import React from 'react';

import shopImg from '../../assets/merchant_img.png';

import PublishIcon from '@mui/icons-material/Publish';
import GradingIcon from '@mui/icons-material/Grading';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import './homePage.css'

const MerchantPrimaryHomeAsset: React.FC = () => {
    return (
        <div className='home-graphic'>
            <div className='horizonal-panel-holder'>
                <div className='horizonal-panel'>
                    <PublishIcon fontSize='large'/>
                    <div style={{fontWeight: '600', fontSize: '18px'}}>Submit an inquiry</div>
                    <p style={{textAlign: 'left'}}>We'll review your product and reach out to potential storefronts!</p>
                </div>
                <div className='horizonal-panel'>
                    <GradingIcon fontSize='large'/>
                    <div style={{fontWeight: '600', fontSize: '18px'}}>Review your matches</div>
                    <p style={{textAlign: 'left'}}>You'll get a list of storefronts that are interested in selling your product.</p>
                </div>
                <div className='horizonal-panel'>
                    <TrendingUpIcon fontSize='large'/>
                    <div style={{fontWeight: '600', fontSize: '18px'}}>Setup your popup!</div>
                    <p style={{textAlign: 'left'}}>Work with storefront to setup your new popup shop!</p>
                </div>
            </div>
            <img src={shopImg} alt="shops" className='home-graphic-img'/>
        </div>
    );
}

export default MerchantPrimaryHomeAsset;