
import React from 'react';

import { useNavigate } from 'react-router-dom';
import AccountComponent from './account/AccountDisplay';
import AccountEditable from './account/AccountEditable';

import { AuthUser } from 'aws-amplify/auth';
import { Authenticator } from '@aws-amplify/ui-react';
import { AccountStatus, useManageContext } from '../utils/context/manageContext';
import { Spinner } from 'react-bootstrap';

type SigninProps = {
}

const Signin: React.FC<SigninProps> = () => {
    const navigate = useNavigate();
    const {getAccount} = useManageContext();

    return (
        <div style={{minHeight: '100vh', paddingTop: '10vh'}}>
            <Authenticator
                signUpAttributes={['email', 'name']}
                initialState='signUp'
                socialProviders={['google']}>
            {({ signOut, user }) => {
                if (user !== undefined) {
                    getAccount(true).then((account) => {
                        if (account === AccountStatus.NotLoggedIn) {
                            <div>
                                We couldn't retreive your account info! Try refreshing the page.
                            </div>
                        } else if (account !== AccountStatus.NotLoaded && account.completed) {
                            navigate('/');
                        } else {
                            navigate('/account');
                        }
                    })
                }
                return (
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                )
            }}
            </Authenticator>
        </div>
    );
}

export default Signin;