import { on } from 'events';
import React, {useState, useEffect} from 'react';
import { Dropdown } from 'react-bootstrap';
import AddressInput from './address';
import DateRangeInput from './date';
import SocialMediaInput, {SocialMediaPreview} from './social-media';
import '../form.css';
import './dropdown.css';

export enum TextInputType {
    TEXT = "text",
    TEXTAREA = "textarea",
    ADDRESS = "address",
    DROWPDOWN = "dropdown",
    DATE_RANGE = "dateRange",
    SOCIAL_MEDIA = "socialMedia",
}

const TextInput: React.FC<{
        label?: string,
        description?: string,
        value: string|undefined,
        alwaysEdit?: boolean,
        inputType?: TextInputType,
        props?: any,
        onChange?: (value: string|undefined) => void,
    }> = ({label, description, value, alwaysEdit, inputType, props, onChange}) => {

    let inputTypeInternal = inputType ? inputType : TextInputType.TEXT;

    let initEdit = getShouldEdit();
    const [edit, setEdit] = useState(initEdit);
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        let initEdit = getShouldEdit()
        setEdit(initEdit);
    }, [alwaysEdit]);

    function getShouldEdit() {
        let initEdit = value === undefined;
        alwaysEdit = alwaysEdit === undefined ? false : alwaysEdit;
        if (alwaysEdit) {
            initEdit = true;
        }
        return initEdit;
    }

    function onCancel() {
        setEdit(false);
        setInternalValue(value);
    }

    function onDone() {
        setEdit(false);
        onChange && onChange(internalValue);
    }

    function onChangeInternal(value: string) {
        setInternalValue(value);
        if (alwaysEdit) {
            onChange && onChange(value);
        }
    }
    
    let inputElement;

    if (inputTypeInternal === TextInputType.TEXTAREA) {
        inputElement = (
            <textarea
                className='text-input'
                value={internalValue}
                onChange={(e) => onChangeInternal(e.target.value)}
                style={{
                    width: '80%'
                }}/>
        );
    } else if (inputTypeInternal === TextInputType.ADDRESS) {
        inputElement = (
            <AddressInput 
                value={internalValue}
                onChange={(newValue) => newValue !== undefined && onChangeInternal(newValue)}     
            />
        );
    } else if (inputTypeInternal === TextInputType.DROWPDOWN) {
        inputElement = (
            <Dropdown onSelect={(eventKey) => {
                eventKey && onChangeInternal(eventKey);
            }}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                {internalValue ? internalValue : "Select"}
                </Dropdown.Toggle>
        
                <Dropdown.Menu>
                { props.options.map((option: string) => {
                    return (
                        <Dropdown.Item eventKey={option}>{option}</Dropdown.Item>
                    );
                })}
                </Dropdown.Menu>
            </Dropdown>
        );
    } else if (inputTypeInternal === TextInputType.DATE_RANGE) {
        inputElement = (
            <DateRangeInput 
                value={internalValue}
                onChange={(newValue) => newValue && onChangeInternal(newValue)}
            />
        );
    } else if (inputTypeInternal === TextInputType.SOCIAL_MEDIA) {
        inputElement = (
            <SocialMediaInput 
                value={internalValue}
                onChange={(newValue) => newValue !== undefined && onChangeInternal(newValue)}
            />
        );
    } else {
        inputElement = (
            <input
                className='text-input'
                type="text"
                value={internalValue}
                onChange={(e) => onChangeInternal(e.target.value)}/>
        );
    }
    
    let previewElement;
    if (inputTypeInternal === TextInputType.SOCIAL_MEDIA) {
        previewElement = (
            <SocialMediaPreview value={internalValue} />
        );
    } else {
        previewElement = (
            <>
            { (value !== undefined && value !== null && value !== "") ? (
                <>
                    {value}
                </>
            ) : (
                <>
                    <i>Not set</i>
                </>
            )}
            </>
        )
    }

    return (
        <div className="form-input">      
            <div style={{
                    display: "flex",
                    width: '100%',
                    textAlign: 'left',
                }}>
                { edit ? (
                <div className='input-body'>
                        { label && 
                            <div className='editing-label'>
                                {label}
                            </div>
                        }
                        { (description && description !== "") &&
                            <div className='editing-description'>
                                {description}
                            </div>
                        }
                        {inputElement}
                        { !alwaysEdit &&
                            <div className='form-button-holder'>
                                <button className='form-button' style={{display: 'inline'}} onClick={onCancel}>Cancel</button>
                                <button className='form-button' style={{display: 'inline'}} onClick={onDone}>Done</button>
                            </div>
                        }
                    </div>
                ) : (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}>
                        <div>
                        { label && 
                            <div style={{
                                fontWeight: '500',
                                marginTop: '2px',
                                display: 'inline'
                            }}>
                                {label}{" - "}
                            </div>
                        }
                        {previewElement}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default TextInput;