import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';

import './address-input.css';


const AddressInput: React.FC<{
  value: string|undefined,
  onChange: (value: string|undefined) => void,
}> = ({value, onChange}) => {
  return (
    <PlacesAutocomplete
      value={value}
      onChange={onChange}
      onSelect={onChange}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'text-input',
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => (
              <div
                {...getSuggestionItemProps(suggestion, {
                  className: 'suggestion-item',
                  // you can also add custom styling for suggestion items
                })}
              >
                <span>{suggestion.description}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default AddressInput;
