import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, FormUpdater } from '../../types/Form';
import { Organization } from '../../types/Organization';
import { Account } from '../../types/Account';
import { useManageContext } from '../../utils/context/manageContext';
import { postInquiry } from '../../utils/api/inquiry';
import { updateInquiry } from '../../utils/api/organization';
import FaqComponent from '../../components/homepage-components/Faq';
import FormDoc from '../../components/form';
import MerchantPrimaryHomeAsset from '../../components/homepage-components/MakerPrimaryHomeAsset';

import './HomePage.css';
import '../PageContainers.css';

type MerchantHomePageProps = {
    account: Account,
    organization: Organization,
}

const MerchantHomePage: React.FC<MerchantHomePageProps> = ({account, organization}) => {
    // Dummy data for the profile, replace with real data as needed
    console.log("merchange home", organization)
    const [showWorkflow, setShowWorkflow] = useState(false);
    const {setOrgInfo, orgInfo} = useManageContext();
    const navigate = useNavigate();

    function handleSubmit() {
        postInquiry().then((inquiryResponse) => {
            console.log("inquiry submitted", inquiryResponse.inquiry)
            setOrgInfo(inquiryResponse.organization);
            setShowWorkflow(false);
        })
    }

    function saveInquiry(inquiry: FormUpdater) {
        updateInquiry(inquiry).then((inquiry) => {
            setOrgInfo({...organization, activeInquiry: inquiry})
        })
    }

    return (
        <div>
            { showWorkflow && orgInfo?.activeInquiry ? (
                <div className="page-container">
                    <FormDoc
                        form={orgInfo.activeInquiry}
                        onSave={saveInquiry}
                        onCancel={() => setShowWorkflow(false)}
                        onSubmit={handleSubmit}
                    />
                </div>
            ) : (
                <>
                <div className='todo-holder'>
                    <h1 style={{fontWeight: 500, fontSize: 40, marginBottom: 5}}>
                            Welcome {account.name}!
                    </h1>
                    <div className='todo-description'>
                        We'll find the perfect store to sell your products in, here's what we need from you:
                    </div>
                    <div className='todo-list'>
                        <ShopFormPreview startForm={() => navigate('/profile')} shop={orgInfo?.shop}/>
                        {/*
                        { orgInfo?.activeInquiry.started ? (
                            <InquiryPreview inquiry={orgInfo?.activeInquiry} showWorkflow={() => setShowWorkflow(true)}/>
                        ) : (
                            <NoActiveInquiryPreview startForm={() => setShowWorkflow(true)} submittedForms={orgInfo?.submittedInquiries}/>
                        )}
                        */}
                    </div>
                </div>
                <MerchantPrimaryHomeAsset />
                <div className='todo-holder'>
                    <FaqComponent collapsible={true}/>
                </div>
                <div className='todo-holder'>
                    <h1 style={{fontWeight: 500, fontSize: 35, marginBottom: 25}}>
                            We're here to help
                    </h1>
                    <div style={{height: '100px', width: '375px',
                        border: 'solid', borderColor: 'silver', borderRadius: '20px',
                        paddingLeft: '20px', paddingTop: '20px',
                        textAlign: 'left'}}>   
                        <div style={{fontWeight: '600'}}>Have Questions?</div>
                        <div style={{marginBottom: '7px'}}>Please email us at <b>help@nookc.com</b></div>
                    </div>
                </div>
                </>
            )}
        </div>
    );
};

const ShopFormPreview: React.FC<{
    startForm: () => void,
    shop?: Form,
}> = ({startForm, shop}) => {
    let hasExistingInquiries = shop !== undefined && shop.completed;
    return (
        <div className='form-preview-container'>
            { hasExistingInquiries ? (
                <>
                    <div style={{fontWeight: '600'}}>Thank you for filling out your profile!</div>
                    If you haven't done so already, please submit an inquiry next!
                </>
            ) : 
                <>
                <div style={{fontWeight: '600'}}>Your profile is not complete!</div>
                <div style={{marginBottom: '7px'}}>Please complete it so we can match you with the right storefronts.</div>
                <button onClick={startForm} style={{
                    border: 'None', fontWeight: '600',
                    backgroundColor: 'white',
                    cursor: 'pointer',  textDecoration: 'underline'}}>
                    Continue Profile
                </button>
                </>
            }
        </div>
    );
}

const NoActiveInquiryPreview: React.FC<{
    startForm: () => void,
    submittedForms: Array<string>|undefined
}> = ({startForm: startInquiry, submittedForms: submittedInquiries}) => {
    let hasExistingInquiries = submittedInquiries !== undefined && submittedInquiries.length > 0;
    return (
        <div className='form-preview-container'>
            { hasExistingInquiries ? (
                <>
                    <div style={{fontWeight: '600'}}>Thank you for your inquiry!</div>
                    We'll email you with updates!
                </>
            ) : 
                <>
                <div style={{fontWeight: '600'}}>You have no inquiries in progress!</div>
                <div style={{marginBottom: '7px'}}>Would you like to start one?</div>
                <button onClick={startInquiry} style={{
                    border: 'None', fontWeight: '600',
                    backgroundColor: 'white',
                    cursor: 'pointer',  textDecoration: 'underline'}}>
                    Start Inquiry
                </button>
                </>
            }
        </div>
    );
}

const InquiryPreview: React.FC<{inquiry: Form, showWorkflow: () => void}> = ({inquiry, showWorkflow}) => {
    console.log("inquiry preview", inquiry)
    return (
        <div className='form-preview-container'>   
            <div style={{fontWeight: '600'}}>You have an inquiry in progress!</div>
            <div style={{marginBottom: '7px'}}>Would you like to continue?</div>
            <button onClick={showWorkflow} style={{
                border: 'None', fontWeight: '600',
                backgroundColor: 'white',
                cursor: 'pointer',  textDecoration: 'underline'}}>
                Continue Inquiry
            </button>
        </div>
    );
}

export default MerchantHomePage;
