
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AccountDisplay from './AccountDisplay';
import AccountEditable from './AccountEditable';
import { Account } from '../../types/Account';

import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';


import '../container.css';

type AccountComponentProps = {
    accountInfo: Account,
    isEditing: boolean,
    setIsEditing: (isEditing: boolean) => void
}

const AccountComponent: React.FC<AccountComponentProps> = ({accountInfo, isEditing, setIsEditing }) => {
    const navigate = useNavigate();
    return (
        <div className='inv-container'>
            { accountInfo.completed && (
                <div className='container-header'>
                    <h1>Account</h1>
                </div>
            )}
            <div className='container-body'>
            
                { !accountInfo.completed ? (
                    <AccountEditable {
                        ...accountInfo
                    } onCancel={() => setIsEditing(false)}/>
                ) : (
                    <div>
                        <div style={{borderBottom: 'solid', borderBlockColor: 'silver', paddingBottom: '20px', marginBottom: '20px'}}>
                            <AccountDisplay {...accountInfo} />
                        </div>
                        <div>
                        <button className='form-button'
                            style={{
                                display: 'inline-flex', alignItems: 'center',
                                fontWeight: '500', backgroundColor: '#f24033', color: 'white'
                            }}
                            onClick={()=>navigate('/profile')}>
                            Edit your Organization's Profile (Required)
                            <ArrowRightAlt/>
                        </button>
                        </div> 
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountComponent;