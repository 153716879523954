import React, { useState, useEffect } from 'react';
import { getOperationsInfo, getAccountInfo } from '../../utils/api/operations';

import './OperationsPage.css'; // CSS file for styling the profile page
import { Organization } from '../../types/Organization';

import OrgView from './OrgView';
import OutreachView from './OutreachView';

type OperationsPageProps = {
}

enum Page {
    OrgView,
    OutReach,
}

const OperationsPage: React.FC<OperationsPageProps> = () => {
    
    const [orgs, setOrgs] = useState<Organization[]>([]);
    const [page, setPage] = useState<Page>(Page.OrgView);

    return (
        <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
            <div
                style={{ width: '20%', padding: 20, paddingTop: 60, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}
            >
                <button
                    style={{
                        border: 'none',
                        background: 'transparent',
                        tabSize: 20,
                        fontSize: 20,
                        cursor: 'pointer',
                        color: page === Page.OrgView ? 'black' : 'gray',
                        display: 'block',
                    }}
                    onClick={() => setPage(Page.OrgView)}>
                    Existing Orgs
                </button>  
                <button
                    style={{
                        border: 'none',
                        background: 'transparent',
                        tabSize: 20,
                        fontSize: 20,
                        cursor: 'pointer',
                        color: page === Page.OutReach ? 'black' : 'gray',
                        display: 'block',
                    }}
                    onClick={() => setPage(Page.OutReach)}>
                    OutReach
                </button>   
            </div>
            <div style={{ width: '80%'}}>
                { page === Page.OrgView && (
                    <OrgView orgs={orgs} setOrgs={setOrgs}/>
                )}
                { page === Page.OutReach && (
                    <OutreachView orgs={orgs} setOrgs={setOrgs}/>
                )}
            </div>
        </div>
    );
};

export default OperationsPage;
