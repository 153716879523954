import { Maker } from "./Maker";
import { Storefront } from "./Storefront";
import { Inquiry } from "./Inquiry";
import { Form } from "./Form";


export enum OrganizationType {
    maker = "maker",
    storefront = "storefront",
}

export type Organization = {
    name: string,
    storefront: Form,
    shop: Form,
    shopProducts: Form[],
    startedForm: boolean,
    activeInquiry: Form,
    orgType: OrganizationType,
    administrators: Array<string>,
    submittedInquiries: Array<string>,
    placeId: string,
}
