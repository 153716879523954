import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import {getPictureUploadUrl} from '../../../utils/api/organization';
import { imgCoalesce } from '../../../utils/coalesce/img';
import { Spinner } from 'react-bootstrap';
import { FormItem, FormItemValue } from '../../../types/Form';
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';


const MiddleIcon: React.FC<{children: React.ReactNode}> = ({children}) => {
    return (
        <div style={{
            position: 'absolute',
            top: '40%',
            left: '40%',
            zIndex: 100,
        }}>
            {children}
        </div>
    );
}

function GreenCheckmark() {
  return <MiddleIcon>
        <CheckCircleIcon sx={{ color: '#b8d8be' }} />
    </MiddleIcon>
}

function RedExclamation() {
  return <MiddleIcon>
    <PriorityHighIcon sx={{ color: '#ff0000' }} />
  </MiddleIcon>;
}

function PhotoSpinner() {
    return <MiddleIcon>
            <Spinner animation="border" role="status">
            </Spinner>
    </MiddleIcon>
}

type pictureValue = {
    k: string,
    t: string,
}

enum UploadStatus {
    NONE,
    UPLOADING,
    SUCCESS,
    FAILURE,
}

const PictureUpload: React.FC<{
    label: string,
    description: string,
    value: pictureValue,
    edit?: boolean,
    existingUrl?: string,
    onChange?: (value: pictureValue) => void
}> = ({label, description, value, edit, existingUrl, onChange}) => {
    const [file, setFile] = useState<File | null>(null);
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.NONE);
    const [previewUrl, setPreviewUrl] = useState<string>(imgCoalesce(existingUrl));
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (file != null) {
            uploadFile();
        }
    }, [file]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const inFile = event.target.files[0];
            setFile(inFile);
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreviewUrl(e.target?.result as string);
            };
            reader.readAsDataURL(inFile);
        }
    };

    const uploadFile = async () => {
        if (!file) {
            alert('No file selected');
            return;
        }
        if (!onChange) {
            alert('No onChange function');
            return;
        }

        try {
            // Get the pre-signed URL from your server
            const {uploadUrl, uploadFields} = await getPictureUploadUrl();
            const formData = new FormData();
            const keyval = uploadFields.key + file.name;
            for (const [key, value] of Object.entries(uploadFields)) {
                let field: string;
                if (key === "key") {
                    field = keyval;
                } else {
                    field = value || "";
                }
                formData.append(key, field);
            }
            formData.append("file", file);
            const xhr = new XMLHttpRequest();
            xhr.open("POST", uploadUrl, true);
            xhr.onload = (e) => {
                setUploadStatus(UploadStatus.SUCCESS);
                onChange({
                    k: keyval,
                    t: "S3",
                })
            }
            xhr.onerror = (e) => {
                console.error('Error uploading the file', e);
                alert('Error uploading the file');
                setUploadStatus(UploadStatus.FAILURE);
            }
            xhr.send(formData);
            setUploadStatus(UploadStatus.UPLOADING);
        } catch (error) {
            console.error('Error uploading the file', error);
            alert('Error uploading the file');
        }
    };

    console.log("picture upload", value)
    return (
        <div>
            { label && 
                <>
                { edit ? (
                    <div className='editing-label'>
                        {label}
                    </div>
                ) : (
                    <div style={{
                        fontWeight: '500',
                        marginTop: '2px',
                    }}>
                        {label}
                    </div>
                )}
                </>

            }
            {description}
            <div style={{position: 'relative', width: 120, height: 120}}>
                <img src={previewUrl} alt="Preview"
                    className='form-image'
                    style={(uploadStatus == UploadStatus.UPLOADING) ? {
                        filter: 'brightness(50%)',
                    } : {}}
                    onClick={() => fileInputRef.current?.click()}/>
                {edit && (
                    <div>
                    { onChange &&
                        <input
                            type="file"
                            onChange={handleFileChange}
                            style={{
                                display: 'none',
                            }}
                            ref={fileInputRef}
                        ></input>
                    }
                    { (uploadStatus == UploadStatus.UPLOADING) && (
                        <PhotoSpinner/>
                    )}
                    { (uploadStatus == UploadStatus.SUCCESS) && (
                        <GreenCheckmark/>
                    )}
                    { (uploadStatus == UploadStatus.FAILURE) && (
                        <RedExclamation/>
                    )}
                    </div>

                )}
            </div>
            <div>
            { edit ? (
                <>
                { (uploadStatus == UploadStatus.NONE) ? (
                    <i>Click on your picture to upload or change</i>
                ) : (
                    <i>Click "Save" or "Next" to save your changes</i>
                )}
                </>
            ) : (
                <div>
                    <i>Edit section to upload/change photo</i>
                </div>
            )}
            </div>
        </div>
    );
}

export default PictureUpload;
