import React, {useEffect, useState} from "react";
import { Form } from "../../types/Form";
import FormDoc, { EditingFormHeader } from "../../components/form";
import { searchPlaces } from '../../utils/api/utils';
import { updateStorefront, updateStorefrontWithGoogle } from '../../utils/api/organization';
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {getFormStyle} from '../../components/form/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FormHeader from "../../components/form/header";

import './profile.css'
import { Organization } from "../../types/Organization";


function GreenCheckmark() {
    return <CheckCircleIcon sx={{ color: '#b8d8be' }} />;
}

enum PageState {
    Home,
    GoogleSearch,
    EditStorefront,
}

const StorefrontProfile: React.FC<{
    orgInfo: Organization,
    setOrgInfo: (org: Organization) => void,
}> = ({
    orgInfo,
    setOrgInfo,
}) => {
    const storefrontInfo = orgInfo?.storefront;
    const navigate = useNavigate();
    const [pageState, setPageState] = useState<PageState>(PageState.Home);
    const [loading, setLoading] = useState<boolean>(false);

    // We use 'formInitEdit' when we want to change if the form is in edit mode
    // (e.g - when we go from google form to storefront form)
    // We use 'formEdit' to track where the form is (e.g - so we can change the header)
    //const [formInitEdit, setFormInitEdit] = useState<boolean>(false);
    const [formEdit, setFormEdit] = useState<boolean>(false);

    useEffect(() => {
        if (formEdit) {
            setPageState(PageState.EditStorefront);
        } else {
            setPageState(PageState.Home);
        }
    }, [ formEdit])

    function setStorefront(storefront: Form) {
        setOrgInfo({
            ...orgInfo,
            storefront: storefront
        });
        if (!storefrontInfo.completed && storefront.completed) {
            navigate('/')
        } 
    }

    function updateWithGoogle(placeId: string) {
        setPageState(PageState.Home);
        setLoading(true);
        updateStorefrontWithGoogle(placeId).then((returnPayload) => {
            setOrgInfo({
                ...orgInfo,
                storefront: returnPayload.storefront,
                placeId: returnPayload.placeId,
            });
            if (!storefrontInfo.completed && returnPayload.storefront.completed) {
                navigate('/')
            } 
            setLoading(false);
        });
    }


    return (
        <div style={{textAlign: 'left'}}>
                { loading && <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                    </Spinner>}
                { (pageState == PageState.EditStorefront || pageState == PageState.Home) &&
                    <div>
                        { (pageState == PageState.Home) &&
                            <>
                                <FormHeader
                                    formTitle="Edit Business Information"
                                    completed={storefrontInfo.completed}
                                />
                                <GooglePreview
                                    placeId={orgInfo.placeId}
                                    onClick={() => setPageState(PageState.GoogleSearch)} />
                            </>
                        }
                        <FormDoc form={storefrontInfo}
                            header={false}
                            onSave={(form) => updateStorefront(form).then((storefront) => {
                                setStorefront(storefront);
                            })}
                            onEditModeChange={(editMode) => setFormEdit(editMode)}
                        />
                    </div>
                }
                { (pageState == PageState.GoogleSearch) &&
                    <GoogleComponent
                        getDetails={updateWithGoogle}
                        onCancel={() => setPageState(PageState.Home)}
                        onNext={() => {
                            setPageState(PageState.Home)
                        }}
                        />
                }
        </div>
    );
};

const GooglePreview: React.FC<{
    placeId?: string,
    onClick: () => void,
}> = ({
    placeId,
    onClick,
}) => {
    let alreadyPopulated: { [key: string]: string } = placeId ? JSON.parse(placeId) : {};
    /*
    let googleInfo = JSON.parse(placeId);
    console.log(googleInfo);
    let alreadyPopulated: Map<string, string> = new Map();
    let storefrontMap = formToMap(storefront);
    Object.entries(googleInfo).forEach(([name, value]) => {
        let storefrontItem = storefrontMap.get(name);
        if (value === storefrontItem && storefrontItem !== undefined) {
            alreadyPopulated.set(name, storefrontItem);
        }
    });
    console.log("storefrontMAp", storefrontMap);
    console.log("googleInfo", googleInfo);
    console.log("alreadyPopulated", alreadyPopulated);
    */
    return (
        <div className='form inv-container' style={{
            backgroundColor: '#eef8ff',
            borderRadius: '10px',
            padding: '10px',
            marginBottom: '10px',
        }}>
            <div className="google-reference-header">
                <div style={{
                    fontWeight: '500',
                    fontSize: '1.5em',
                    display: 'inline',
                }}>
                    {"Google Reference "}
                </div>
                <div style={{
                    display: 'inline',
                }}>
                    {"- "}
                    <button
                        className="link-button" style={{fontSize: '20px'}}
                        onClick={onClick}
                        >
                        Edit
                    </button>
                </div>
            </div>

            <div style={{minHeight: '40px'}}>
                { (placeId === undefined || placeId === null || placeId === "") ? (
                    <div>
                        No store found on Google. Click 'Edit' to search for your store and autofill some fields.
                    </div>
                ) : (
                    <div>
                        Found from Google:
                        <div style={{paddingLeft: 20, color: '#494949'}}>
                        {
                            Object.entries(alreadyPopulated).map(([key, value]) => {
                                if (key === 'place_id') return
                                return (
                                    <div key={key}>
                                        {key} : {value}
                                    </div>
                                );
                            })
                        }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const GoogleComponent: React.FC<{
    getDetails: (placeId: string) => void,
    onCancel: () => void,
    onNext: () => void,
}> = ({
    getDetails,
    onCancel,
    onNext,
}) => {
    const [searchResults, setSearchResults] = useState<google.maps.places.PlaceResult[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);


    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {    
            search();
        }
    }
    
    function search() {
        setLoading(true);
        searchPlaces(searchQuery).then((results) => {
            console.log(results)
            setSearchResults(results);
            setLoading(false);
        });
    }
    let formStyle = getFormStyle();
    return (
        <div className="form inv-container" style={formStyle}>
            <EditingFormHeader
                sectionTitle="Search for your store"
                onExit={onCancel} />
            <p>
                Search for your store to help us populate some of your storefront information.
            </p>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <input
                    className='text-input'
                    type="text"
                    placeholder="Joe's Ice Cream Shop San Francisco, CA"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    />
                <button className="form-button" onClick={search} style={{width: '200px'}}>
                    Search Google
                </button>
            </div>
            <div>
                { loading && <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>}
                { searchResults.length > 0 && <h3>Results</h3> }
                { searchResults.map((result) => (
                    <div key={result.place_id} className="google-search-result">
                        <p><u>{result.name}</u></p>
                        <p>{result.formatted_address}</p>
                        <button className="form-button" onClick={() => result.place_id && getDetails(result.place_id)}>
                            Use this place
                        </button>
                    </div>
                ))}
            </div>
            <div className='form-button-holder'>
                <button className='form-back-button' onClick={onCancel}>{"Cancel"}</button>
                <button className='form-next-button' onClick={onNext}>{"Save and Continue"}</button>
            </div>
        </div>
    )
}


export default StorefrontProfile;