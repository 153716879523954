// aws-exports.js (or aws-exports.ts)
const env = process.env.REACT_APP_ENV || 'prod';
const oauth_redirect_uri = env === 'prod' ? 'https://www.nookc.com/' : 'http://localhost:3000/';

const awsconfig = {
    aws_project_region: 'us-west-2',
    aws_cognito_region: 'us-west-2',
    aws_user_pools_id: 'us-west-2_kxm0MhMcL',
    aws_user_pools_web_client_id: '3lcanjq3oqh3ktthg6cm2ofck0',
    oauth: {
      domain: "oauth.nookc.com",
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: oauth_redirect_uri + "signin",
      redirectSignOut: oauth_redirect_uri + "signin",
      responseType: "code"  // or 'token', depending on the flow you have configured
    },
  };

export default awsconfig;