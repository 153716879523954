
import {BaseApiUrl, getAuthHeader} from './base'
import { Storefront } from '../../types/Storefront';

export async function getStorefronts(): Promise<Storefront[]> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/storefronts`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }
        }).then( 
            response => response.json()
        ).then((data) => {
            return data.storefronts;
        });
    });
}
