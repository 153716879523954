
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useManageContext, AccountStatus } from '../../utils/context/manageContext';
import { Form } from '../../types/Form';
import FormPreview from '../../components/form/preview';
import FormDoc from '../../components/form';
import FormListComponent from '../../components/form/list';
import { updateMerchant, putMerchantProduct, deleteMerchantProduct } from '../../utils/api/organization';
import { Organization } from '../../types/Organization';


enum PageState {
    Profile,
    Products,
    BusinessInfo,
}

const MakerProfile: React.FC<{
    orgInfo: Organization,
    setOrgInfo: (org: Organization) => void,
}> = ({orgInfo, setOrgInfo}) => {
    const navigate = useNavigate();
    const makerInfo = orgInfo.shop;
    const [pageState, setPageState] = useState<PageState>(PageState.Profile);

    return (
        <div style={{textAlign: 'left'}}>
            { (pageState == PageState.Profile) &&
                <>
                <div style={{fontSize: 30, marginTop: 25}}>
                    Maker Profile
                </div>
                <div style={{marginBottom: 15}}>
                    Please fill out your business information and add any products you wish to sell through nookc.
                </div>
                <div style={{borderTop: 'solid', borderBottom: 'solid', borderColor: 'silver', paddingTop: 10, paddingBottom: 10}}>
                    <div style={{fontSize: 25, marginBottom: 10}}>
                        Your Business
                    </div>
                    <FormPreview form={makerInfo} key={makerInfo.name} onSelect={() => setPageState(PageState.BusinessInfo)}>
                        <button className='form-button' onClick={() => setPageState(PageState.BusinessInfo)}>
                            Edit Business Information
                        </button>
                        <></>
                    </FormPreview>
                </div>
                </>
            }
            { (pageState == PageState.BusinessInfo) &&
                <div>
                    <FormDoc form={{
                            ...makerInfo,
                            name: "Business Information"
                        }}
                        onSave={(form) => {
                            updateMerchant(form).then((maker) => {
                                setOrgInfo({...orgInfo, shop: maker});
                                if (!makerInfo.completed && maker.completed) {
                                    navigate('/')
                                }
                            })
                        }}
                        onCancel={() => {
                            setPageState(PageState.Profile);
                        }}
                    />
                </div>
            }

            { (pageState != PageState.BusinessInfo) &&
                <div>
                    { (pageState == PageState.Profile) &&
                        <div style={{fontSize: 25, marginBottom: 10, paddingTop: 10}}>
                            Your Products
                        </div>
                    }
                    <FormListComponent
                        formList={orgInfo.shopProducts}
                        onNew={() => {
                            putMerchantProduct().then((shopProducts) => {
                                if (orgInfo) {
                                    setOrgInfo({...orgInfo, shopProducts});
                                }
                            })
                        }}
                        onUpdate={(form) => {
                            putMerchantProduct(form).then((shopProducts) => {
                                if (orgInfo) {
                                    setOrgInfo({...orgInfo, shopProducts});
                                }
                            })
                        }}
                        onRemove={(form) => {
                            deleteMerchantProduct(form).then((shopProducts) => {
                                if (orgInfo) {
                                    setOrgInfo({...orgInfo, shopProducts});
                                }
                            })
                        }}
                        onSelect={(form) => {
                            setPageState(PageState.Products);
                        }}
                        onCancel={() => {
                            setPageState(PageState.Profile);
                        }}
                    />
                </div>
            }
        </div>
    );
};

export default MakerProfile;