import { fetchAuthSession } from 'aws-amplify/auth';


export const BaseAccountUrl = (process.env.REACT_APP_ENV === 'local') ? 'http://localhost:9000' : 'https://api.nookc.com';
export const BaseApiUrl = (process.env.REACT_APP_ENV === 'local') ? 'http://localhost:9001' : 'https://api.nookc.com';

export async function getAuthHeader(): Promise<string|undefined> {
    console.log(process.env.REACT_APP_ENV);
    const { accessToken, idToken }  = (await fetchAuthSession()).tokens ?? {};
    let token;
    if (process.env.REACT_APP_ENV === 'local') {
        token = accessToken?.toString();
    } else {
        token = idToken?.toString();
    }
    return token;
}