
import {BaseAccountUrl, getAuthHeader} from './base'
import { Organization } from '../../types/Organization';
import { Account } from '../../types/Account';

export type OperationsResponse = {
    organizations: Organization[],
};

export async function getOperationsInfo(): Promise<OperationsResponse> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseAccountUrl}/operations/organizations`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error("Unauthenticated")
            }
        });
    });
}


export async function getAccountInfo(account_id: string): Promise<{account: Account}> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseAccountUrl}/operations/account?id=${account_id}`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error("Unauthenticated")
            }
        });
    });
}