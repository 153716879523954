import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import './StorefrontCard.css'; // Assuming you will have a CSS file for styling
import { Storefront } from '../../../../types/Storefront';

type SelectableProps = {
    onSelect: () => void;
    onUnselect: () => void;
};

const StorefrontCard: React.FC<Storefront & SelectableProps> = ({ primaryImg, title, description, onSelect, onUnselect }) => {
    const [selected, setSelected] = useState(false);

    let cardClass = "card";
    if (selected) {
        cardClass += " selected";
    }

    function handleSelect() {
        let newSelected = !selected;
        setSelected(newSelected);
        if (newSelected) {
            onSelect();
        } else {
            onUnselect();
        }
    }

    return (
        <div className={cardClass} onClick={handleSelect}>
            <img src={primaryImg.url} alt={title} className="card-image" />
            <div className="card-content">
                <h3 className="card-title">{title}</h3>
                <p className="card-description">{description}</p>
            </div>
        </div>
    );
};

export default StorefrontCard;
