import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useManageContext } from '../../utils/context/manageContext';

import { Account } from '../../types/Account';
import { Form } from '../../types/Form';

import StorefrontPrimaryHomeAsset from '../../components/homepage-components/StorefrontPrimaryHomeAsset';
import FaqComponent from '../../components/homepage-components/Faq';

import '../PageContainers.css'; 


const NewInquiry = {
    id: '',
    productName: '',
    productDescription: '',
    spaceNeeded: '',
    itemType: '',
    avgPricePoint: 0,
    customerType: '',
    idealTimeFrame: '',
    submitted: false,
}

type StorefrontHomePageProps = {
    account: Account,
}

const StorefrontHomePage: React.FC<StorefrontHomePageProps> = ({account}) => {
    // Dummy data for the profile, replace with real data as needed
    const {orgInfo} = useManageContext();
    const navigate = useNavigate();
    const [showWorkflow, setShowWorkflow] = useState(false);

    function handleSubmit() {
    }

    return (
        <div>
            { showWorkflow ? (
                <>
                    <h1 style={{color: 'grey', fontWeight: 300,
                        marginBottom: '25px', marginTop: '25px'}}>
                    Finish your storefront profile
                    </h1>
                </>
            ) : (
                <>
                <div className='todo-holder'>
                    <h1 style={{fontWeight: 500, fontSize: 40, marginBottom: 5}}>
                            Welcome {account.name}!
                    </h1>
                    <div className='todo-description'>
                        We'll find the perfect products to sell in your store, here's what we need from you:
                    </div>
                    <StorefrontPreview storefront={orgInfo?.storefront} continueStorefront={() => navigate("/profile")}/> 
                </div>
                <StorefrontPrimaryHomeAsset/>
                <div className='todo-holder'>
                    <FaqComponent collapsible={true}/>
                </div>
                <div className='todo-holder'>
                    <h1 style={{fontWeight: 500, fontSize: 35, marginBottom: 25}}>
                            We're here to help
                    </h1>
                    <div style={{height: '100px', width: '375px',
                        border: 'solid', borderColor: 'silver', borderRadius: '20px',
                        paddingLeft: '20px', paddingTop: '20px',
                        textAlign: 'left'}}>   
                        <div style={{fontWeight: '600'}}>Have Questions?</div>
                        <div style={{marginBottom: '7px'}}>Please email us at <b>help@nookc.com</b></div>
                    </div>
                </div>
                </>
            )}
        </div>
    );
};

const StorefrontPreview: React.FC<{storefront: Form|undefined, continueStorefront: () => void}> = ({storefront, continueStorefront}) => {

    return (
        <div style={{height: '150px', width: '375px',
            border: 'solid', borderColor: 'silver', borderRadius: '20px',
            paddingLeft: '20px', paddingTop: '20px',
            textAlign: 'left'}}>   
            { storefront !== undefined && storefront.completed ? (
                <>
                    <div style={{fontWeight: '600'}}>Thank you for completing your storefront profile!</div>
                    <div>We will get back to you with interested popup shops. You can review your store profile {" "}
                        <button onClick={continueStorefront} className='link-button' style={{color: '#666666'}}>
                            here
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div style={{fontWeight: '600'}}>You haven't completed your store profile!</div>
                    <div style={{marginBottom: '7px'}}>Please complete it so we can match you with interested merchants.</div>
                    <button onClick={continueStorefront} style={{
                        border: 'None', fontWeight: '600',
                        backgroundColor: 'white',
                        cursor: 'pointer',  textDecoration: 'underline'}}>
                        Continue profile
                    </button>
                </>
            )}
        </div>
    );
}

export default StorefrontHomePage;
