
import {BaseApiUrl, getAuthHeader} from './base'
import { Form, FormUpdater } from '../../types/Form';


export async function updateStorefrontWithGoogle(placeId: string): Promise<{
        storefront: Form,
        placeId: string,
    }> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/organization/storefront/google_link`, {
            method: 'PUT',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({placeId: placeId})
        }).then( 
            response => response.json()
        ).then((data) => {
            return data.organization;
        });
    });
}


export async function updateStorefront(storefront: FormUpdater): Promise<Form> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/organization/storefront`, {
            method: 'PUT',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(storefront)
        }).then( 
            response => response.json()
        ).then((data) => {
            return data.organization.storefront;
        });
    });
}

export async function updateMerchant(maker: FormUpdater): Promise<Form> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/organization/shop`, {
            method: 'PUT',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(maker)
        }).then( 
            response => response.json()
        ).then((data) => {
            return data.organization.shop;
        });
    });
}

export async function updateInquiry(inquiry: FormUpdater): Promise<Form> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/organization/inquiry`, {
            method: 'PUT',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inquiry)
        }).then( 
            response => response.json()
        ).then((data) => {
            return data.organization.activeInquiry;
        });
    });
}

export async function putMerchantProduct(product?: FormUpdater): Promise<Form[]> {
    let body = product ? JSON.stringify(product) : '{}';
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/organization/shop/product`, {
            method: 'PUT',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
            body: body
        }).then( 
            response => response.json()
        ).then((data) => {
            return data.organization.shopProducts;
        });
    });
}

export async function deleteMerchantProduct(product: FormUpdater): Promise<Form[]> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/organization/shop/product`, {
            method: 'DELETE',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(product)
        }).then( 
            response => response.json()
        ).then((data) => {
            return data.organization.shopProducts;
        });
    });
}

export async function getPictureUploadUrl(): Promise<{
    uploadUrl: string,
    uploadFields: {
        key: string
        AWSAccessKeyId: string
        policy: string
        signature: string
    }
}> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/organization/storefront/asset-location`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }
        }).then( 
            response => response.json()
        ).then((data) => {
            return data
        });
    });
}