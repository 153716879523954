
import { on } from 'events';
import React, {useState, useEffect} from 'react';

import '../form.css';
import { unescape } from 'querystring';

const NumberInput: React.FC<{
        label: string,
        description?: string,
        value: number|undefined,
        alwaysEdit?: boolean,
        onChange?: (value: number|undefined) => void,
    }> = ({label, description, value, alwaysEdit, onChange}) => {

    let initEdit = getShouldEdit();
    const [edit, setEdit] = useState(initEdit);
    const [internalValue, setInternalValue] = useState(value);

    useEffect(() => {
        let initEdit = getShouldEdit()
        setEdit(initEdit);
    }, [alwaysEdit]);

    function getShouldEdit() {
        let initEdit = value === undefined;
        alwaysEdit = alwaysEdit === undefined ? false : alwaysEdit;
        if (alwaysEdit) {
            initEdit = true;
        }
        return initEdit;
    }

    function onCancel() {
        setEdit(false);
        setInternalValue(value);
    }

    function onDone() {
        setEdit(false);
        onChange && onChange(internalValue);
    }

    function onChangeInternal(value: number) {
        setInternalValue(value);
        if (alwaysEdit && onChange !== undefined) {
            onChange(value);
        }
    }

    return (
        <div className="form-input">
            { edit ? (
                <div className='input-body'>
                    { label && 
                        <div className='editing-label'>
                            {label}
                        </div>
                    }
                    <input className='text-input' type="number" step="0.01" min="0" value={internalValue} onChange={(e) => onChangeInternal(parseFloat(e.target.value))} />
                    { (!alwaysEdit && onChange !== undefined) &&
                        <div className='form-button-holder'>
                        <button className='form-button' style={{display: 'inline'}} onClick={onDone}>Done</button>
                        <button className='form-button' style={{display: 'inline'}} onClick={onCancel}>Cancel</button>
                        </div>
                    }
                </div>
            ) : (
                <>
                    { label && 
                        <div style={{
                            fontWeight: '500',
                            marginBottom: '5px',
                            display: 'inline'
                        }}>
                            {label}{" - "}
                        </div>
                    }
                    { value !== undefined && value !== 0 ? (
                        <>
                            {value}
                        </>
                    ) : (
                        <>
                            <i>Not set</i>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default NumberInput;