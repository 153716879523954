import React, {useEffect, useState, useContext} from 'react';
import { BrowserRouter, Link, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import NewCustomerHomePage from './pages/home-page/NewCustomerHomePage';
import MerchantHomePage from './pages/home-page/MakerHomePage';
import StorefrontHomePage from './pages/home-page/StorefrontHomePage';
import ProfilePage from './pages/profile-page';
import AccountPage from './pages/account-page';
import OperationsPage from './pages/operations-page';
import FaqPage from './pages/faq-page';
import TosPage from './pages/docs/TermsOfServicePage';
import PrivacyPage from './pages/docs/PrivacyPage';

import ManageProvider, {AccountStatus, useManageContext} from './utils/context/manageContext';

import Header from './components/header-footer/Header';
import Footer from './components/header-footer/Footer';
import Signin from './components/Signin';
import AccountEditable from './components/account/AccountEditable';

import { Spinner } from 'react-bootstrap';


import './App.css';

import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);




function App() {

  return (
    <div className="App">
      <BrowserRouter>
          <ManageProvider>
          <Header/>
            <Routes>
                <Route path="/" element={
                    <AuthRequired allowUnathorized={true}>
                      <HomePage/>
                    </AuthRequired>
                }/>
                <Route path="/faq" element={
                    <FaqPage/>
                }/>
                <Route path="/docs/privacy" element={
                    <PrivacyPage/>
                }/>
                <Route path="/docs/tos" element={
                    <TosPage/>
                }/>
                <Route path="/profile" element={
                    <AuthRequired unauthenticatedReroute='/signin'>
                      <ProfilePage/>
                    </AuthRequired>
                }/>
                <Route path="/account" element={
                    <AuthRequired unauthenticatedReroute='/signin'>
                      <AccountPage/>
                    </AuthRequired>
                }/>
                <Route path='/signin' element={
                    <Signin/>
                }/>
                <Route path="/operations" element={
                    <AuthRequired unauthenticatedReroute='/signin'>
                      <OperationsPage/>
                    </AuthRequired>
                }/>
            </Routes>
            <Footer/>
          </ManageProvider>
      </BrowserRouter>
    </div>
  )
}

type AuthRequiredProps = {
  unauthenticatedReroute?: string;
  allowUnathorized?: boolean;
  children: React.ReactNode;
}

const AuthRequired: React.FC<AuthRequiredProps> = ({children, unauthenticatedReroute, allowUnathorized}) => {
  const { accountInfo, getAccount } = useManageContext();
  const navigate = useNavigate();

  useEffect(() => {
    getAccount()
  }, [])

  useEffect(() => {
    let authenticated = accountInfo !== AccountStatus.NotLoggedIn
    if (unauthenticatedReroute === undefined) {
      unauthenticatedReroute = '/'
    }
    if (!(authenticated || allowUnathorized)) {
      navigate(unauthenticatedReroute);
    }
  }, [accountInfo])

  return (
    <>
      { (accountInfo === AccountStatus.NotLoaded) ? (
        <>
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </>
      ) : (
        <>
          {(accountInfo !== AccountStatus.NotLoggedIn || allowUnathorized) ? (
            <>
              {children}
            </>
          ):(
            <div>
              Unauthorized!
            </div>
          )}
        </>
      )}
    </>
  )
}

const HomePage: React.FC = () => {
  const { accountInfo, orgInfo } = useManageContext();
  console.log("HomePage", accountInfo);
  return (
    <>
      { (orgInfo === null || accountInfo === AccountStatus.NotLoaded || accountInfo === AccountStatus.NotLoggedIn) ? (
        <NewCustomerHomePage/>
      ) : (
        <>
          { orgInfo.orgType === 'maker' &&
            <MerchantHomePage account={accountInfo} organization={orgInfo}/>
          }
          { orgInfo.orgType === 'storefront' &&
            <StorefrontHomePage account={accountInfo}/>
          }
          { orgInfo.orgType !== 'maker' && orgInfo.orgType !== 'storefront' && (
            <div>
              Please complete your account registration.
              <AccountEditable {...accountInfo} />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default App;
