import React from 'react';

import StorefrontIcon from '@mui/icons-material/Storefront';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SellIcon from '@mui/icons-material/Sell';

import storeImg from '../../assets/storefront_img.png';
import './homePage.css'

const StorefrontPrimaryHomeAsset: React.FC = () => {
    const isMobile = window.innerWidth < 600;
    let panelStyle = isMobile ? {} : {height: '220px'};
    return (
        <div className='home-graphic'>
            <div className='horizonal-panel-holder'>
                <div className='horizonal-panel' style={panelStyle}>
                    <StorefrontIcon fontSize='large'/>
                    <div style={{fontWeight: '600', fontSize: '18px'}}>Fill out your store profile</div>
                    <p style={{textAlign: 'left'}}>
                        Click the account icon in the top right corner, then "My Organization".
                    </p>
                </div>
                <div className='horizonal-panel' style={panelStyle}>
                    <HandshakeIcon fontSize='large'/>
                    <div style={{fontWeight: '600', fontSize: '18px'}}>Review potential popup shops</div>
                    <p style={{textAlign: 'left'}}>
                        We'll look for the right products to launch a popup shop in your store. Once we find a match, we'll reach out to you!
                    </p>
                </div>
                <div className='horizonal-panel' style={panelStyle}>
                    <SellIcon fontSize='large'/>
                    <div style={{fontWeight: '600', fontSize: '18px'}}>Sell more products</div>
                    <p style={{textAlign: 'left'}}>
                        After you launch a popup shop, we'll continue to help you sell more products and reach more customers!
                    </p>
                </div>
            </div>
            <img src={storeImg} alt="shops" className='home-graphic-img'/>
        </div>
    );
}

export default StorefrontPrimaryHomeAsset;