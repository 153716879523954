

export function getFormStyle(formStyle?: any) {
    formStyle = formStyle || {};
    const isMobile = window.innerWidth < 600;
    const height = isMobile ? 'calc(100vh - 180px)' : 'calc(100vh - 265px)';
    return {
        ...formStyle,
        height: height,
        overflow: 'scroll',
    }
}