
import {BaseAccountUrl, getAuthHeader} from './base'
import { Account } from '../../types/Account';
import { Organization } from '../../types/Organization';


export type AccountResponse = {
    account: Account|null,
    organization: Organization|null,
};

export async function getAccountInfo(): Promise<AccountResponse> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseAccountUrl}/account`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error("Unauthenticated")
            }
        });
    });
}

export async function updateAccountInfo(account: Partial<Account>, accountId?: string): Promise<AccountResponse> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        let queryParams = "";
        if (accountId !== undefined) {
            queryParams = `?accountId=${accountId}`;
        }
        return fetch(`${BaseAccountUrl}/account${queryParams}`, {
            method: 'PUT',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(account)
        }).then( 
            response => response.json()
        );
    });
}
