import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useManageContext, AccountStatus } from '../../utils/context/manageContext';
import { Form, FormItem } from '../../types/Form';
import FormDoc from '../../components/form';
import FormListComponent from '../../components/form/list';
import { Account, AccountType } from '../../types/Account';
import { updateMerchant, putMerchantProduct, deleteMerchantProduct } from '../../utils/api/organization';
import { updateStorefront } from '../../utils/api/organization';
import { OrganizationType } from '../../types/Organization';
import StorefrontProfile from './StorefrontProfile';
import MakerProfile from './MakerProfile';

import '../PageContainers.css'; // CSS file for styling the profile page


type ProfilePageProps = {
}

const ProfilePage: React.FC<ProfilePageProps> = () => {

    const navigate = useNavigate();
    const { orgInfo, accountInfo, setOrgInfo } = useManageContext();
    const [storefrontInfo, setStorefrontInfo] = useState<Form|undefined>(orgInfo?.storefront);
    const [makerInfo, setMerchantInfo] = useState<Form|undefined>(orgInfo?.shop);

    return (
        <div>
            {(accountInfo == AccountStatus.NotLoaded || accountInfo == AccountStatus.NotLoggedIn) && (
                <div className="page-container">
                    Loading...
                </div>
            )}
            {(orgInfo?.orgType === OrganizationType.maker) && makerInfo && (
                <div className="page-container">
                    <MakerProfile
                        orgInfo={orgInfo}
                        setOrgInfo={setOrgInfo}
                    />
                </div>
            )}
            {(orgInfo?.orgType === OrganizationType.storefront) && storefrontInfo && (
                <div className="page-container">
                    <StorefrontProfile
                        orgInfo={orgInfo}
                        setOrgInfo={setOrgInfo}
                    />
                </div>
            )}
            {orgInfo?.orgType !== OrganizationType.maker &&
                orgInfo?.orgType !== OrganizationType.storefront && (
                <div className="page-container">
                    Please choose an account type in on the <a href="/account"> account </a> page.
                </div>
            )}
        </div>
    );
};

export default ProfilePage;
