import React from "react"

import { Form } from '../../types/Form';
import { imgCoalesce } from '../../utils/coalesce/img';

const FormPreview: React.FC<{
    form: Form,
    onSelect?: () => void,
    children: React.ReactNode[]
}> = ({ form, onSelect, children}) => {
    let name = form.preview ? form.preview.name : form.name
    let image = form.preview ? form.preview.img.url : null
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            margin: 10
        }}>
            <div style={{width: 100, height: 100}}>
                <img className='form-image'
                    src={imgCoalesce(image)}
                    onClick={onSelect}
                />
            </div>
            <div style= {{marginLeft: 20}}>
                <div style={{fontSize: 24}}>
                    {name}
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )

}

export default FormPreview