import React, { useState, useEffect } from 'react';
import { getOperationsInfo, getAccountInfo } from '../../utils/api/operations';

import './OperationsPage.css'; // CSS file for styling the profile page
import { Organization } from '../../types/Organization';
import { Button, Dropdown } from 'react-bootstrap';
import { Account } from '../../types/Account';
import { Form } from '../../types/Form';
import FormDoc from '../../components/form';
import { updateAccountInfo } from '../../utils/api/account';
import {AccountType} from '../../types/Account';
import { searchPlaces, getPlaceDetails } from '../../utils/api/utils';

type OutreachViewProps = {
    orgs: Organization[]
    setOrgs: (orgs: Organization[]) => void
}

const OutreachView: React.FC<OutreachViewProps> = ({orgs, setOrgs}) => {

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<google.maps.places.PlaceResult[]>([]);

    useEffect(() => {
        getOperationsInfo().then((response) => {
            console.log(response);
            setOrgs(response.organizations);
        });
    }, []);

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            searchPlaces(searchQuery).then((results) => {
                console.log(results)
                setSearchResults(results);
            });
        }
    }

    return (
        <div style={{
            paddingTop: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <div>Search for your store</div>
            <input
                className='text-input'
                type="text"
                value={searchQuery}
                width='50%'
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                />
            <div>
                <h3>Results</h3>
                { searchResults.map((result) => (
                    <div key={result.place_id} style={{}}>
                        <p>name: {result.name}</p>
                        <p>website: {result.website}</p>
                        {/*
                        <button style={{background: 'none', borderRadius: 15, borderWidth: 1}}>
                            Use this place
                        </button>
                        */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OutreachView;
