
import {BaseApiUrl, getAuthHeader} from './base'
import { Inquiry } from '../../types/Inquiry';
import { Organization } from '../../types/Organization';

type InquiryResponse = {
    inquiry: Inquiry,
    organization: Organization

}
export async function postInquiry(): Promise<InquiryResponse> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/inquiry`, {
            method: 'POST',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            },
        }).then( 
            response => response.json()
        );
    });
}

export async function getInquiry(): Promise<Inquiry|null> {
    /*
    //TODO: figure out if cognito uses network to fetchAuthSession.
    // If they do, which they probably dont, then we might want to manage creds
    // internally. Otherwise, remove them from the context.
    const { setIdToken, setAccessToken, setAccountInfo } = useManageContext();

    fetchAuthSession().then((authSession) => {
        const { accessToken, idToken } = authSession.tokens ?? {}
        if (accessToken !== undefined && idToken !== undefined) {
            return fetch(`${BaseUrl}/account`)
                .then((response) => response.json())
                .then((data) => data);
        } else {
            throw new Error("Unauthenticated")
        }
    })
    */
   return null;
}
