import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <footer style={{ backgroundColor: '#f8f9fa', marginTop: 'auto', padding: '20px 0' }}>
      <Container>
        <Row>
          <Col md={4}>
            <Typography variant="h6">NookC</Typography>
          </Col>
          <Col md={4}>
            {/*<Link href="#" style={{ display: 'block', margin: '5px 0' }}>Contact Us</Link> */}
            <Typography variant='body1'> Contact us at <b>help@nookc.com</b></Typography>
          </Col>
          <Col md={4}>
            {/*
            <Typography variant="h6">Follow Us</Typography>
             Icons or links to social media could go here */}
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '20px' }}>
              © {new Date().getFullYear()} NookC. All rights reserved.
            </Typography>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
