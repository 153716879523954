
import { useState, useEffect } from 'react';
import { FormItem, FormItemValue } from '../../../types/Form';
import FormDoc from '..';

import TextInput, { TextInputType } from './TextInput';
import NumberInput from './NumberInput';
import PictureUpload from './PictureUpload';
import CardSelect from './card-select';
import { Spinner } from 'react-bootstrap';

const FormInput: React.FC<{
    item:FormItem,
    editMode?: boolean,
    handleInputChange?: (item: FormItemValue, value: any) => void,
}> = ({
    item,
    editMode,
    handleInputChange,
}) => {
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(false);
    }, [item.memVal]);

    function handleInputChangeInternal(item: FormItemValue, value: any) {
        handleInputChange !== undefined && handleInputChange(item, value);
    }

    if (item.type === 'number') {
        return (
            <NumberInput
                label={item.name}
                description={item.description}
                value={item.memVal}
                alwaysEdit={editMode}
                onChange={handleInputChange !== undefined ?
                    (value) => handleInputChangeInternal(item, value) :
                    undefined
                } />
        );
    } else if (item.type === 'image') {
        return (
            <PictureUpload
                label={item.name}
                description={item.description}
                value={item.memVal}
                edit={editMode}
                existingUrl={item.props.url}
                onChange={handleInputChange !== undefined ?
                    (value) => handleInputChangeInternal(item, value) :
                    undefined
                } />
        );
    } else if (item.type === 'apiMultiSelect') {
        return (
            <CardSelect
                label={item.name}
                description={item.description}
                value={item}
                edit={editMode ? true : false}
                onChange={handleInputChange !== undefined ?
                    (value) => handleInputChangeInternal(item, value) :
                    undefined
                } />
        );
    } else {
        return (
            <TextInput
                label={item.name}
                description={item.description}
                value={item.memVal}
                alwaysEdit={editMode}
                props={item.props}
                inputType={item.type as TextInputType}
                onChange={handleInputChange !== undefined ?
                    (newValue) => handleInputChangeInternal(item, newValue) :
                    undefined
                }
            />
        );
    }
}

export default FormInput;

/*
At some point in time I thought it might be cool to allow recursive lists... I no longer think that would be cool.
Really this should be deleted..

    if (loading && !(item.type==="formList")) {
        return (
            <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>
        );
    } else if (item.type === "form") {
        return (
            <>
                <h1>{item.name}</h1>
                <FormDoc form={item.memVal} onSave={(itemform) => {
                    handleInputChangeInternal(item, itemform);
                }}/>
            </>
        );
    } else if (item.type === "formList") {
        return (
            <>
                <div style={{}}>
                    <h2 style={{display: 'inline'}}>{item.name}</h2>
                    <button style={{
                        display: 'inline',
                        border: 'none',
                        marginLeft: '10px',
                        cursor: 'pointer',
                    }} onClick={() => {
                        let newForm = item.props.newForm;
                        let newMemVal = item.memVal;
                        newMemVal.push(newForm as Form);
                        handleInputChangeInternal(item, newMemVal);
                    }}>Add New</button>
                </div>
                
                {item.memVal.map((itemForm: Form, index: number) => {
                    return (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }} key={index}>
                            <FormDoc form={itemForm}
                                width='80%'
                                onSave={(saveForm) => {
                                    // The returning form will only have the edited values. However,
                                    // we need to save the entire form (don't have recursive logic for nested forms),
                                    // so we need to merge the edited form with the original form.
                                    let newMemVal = item.memVal;
                                    newMemVal[index] = saveForm;
                                    handleInputChangeInternal(item,newMemVal);
                                }}
                                onCancel={() => {
                                    let newMemVal = item.memVal;
                                    newMemVal.splice(index, 1);
                                    handleInputChangeInternal(item, newMemVal);
                                }}
                            />
                        </div>
                    );
                })}
            </>
        );
    } else 
    */