
import React, {useState} from 'react';
import Box from '@mui/material/Box';
import { Account, AccountType } from '../../types/Account';
import { Grid } from '@mui/material';



const AccountDisplay: React.FC<Account> = ({ id, name, email, accountType, organization }) => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <p><b>Name:</b> {name}</p>
                    <p><b>Email:</b> {email}</p>
                    <p><b>Organization:</b> {organization}</p>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccountDisplay;
