import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'react-bootstrap';

import MerchantImg from '../../assets/merchant_square.png';
import StorefrontImg from '../../assets/storefront_square.png';

import PublishIcon from '@mui/icons-material/Publish';
import GradingIcon from '@mui/icons-material/Grading';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import StorefrontIcon from '@mui/icons-material/Storefront';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SellIcon from '@mui/icons-material/Sell';
import StoreIcon from '@mui/icons-material/Store';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

import FaqComponent from '../../components/homepage-components/Faq';

import './NewCustomerHomePage.css';
import '../PageContainers.css';



type NewCustomerHomePageProps = {
}

const NewCustomerHomePage: React.FC<NewCustomerHomePageProps> = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const navigate = useNavigate();
    return (
        <>
            <div className='section-one'>
                <div className='section-one-header'>
                    <div className='catch-phrase'>
                        Empowering Pop-Up Partnerships.
                    </div>
                    <div className='sub-catch-phrase'>
                        Connecting pop-ups with storefronts, optimizing spaces for mutual success and endless possibilities.
                    </div>
                </div>
                {/*<img src={newCustomerImg} style={{width:300, height: 300, borderRadius: 30}}/>*/}
            </div>
            <div className='section-two'>
                <h1 style={{fontSize: 50, marginBottom: 70}}>
                    How can we help?
                </h1>
                <div className='info-button-holder'>
                    <a className='info-button' href='#storefront-section'>
                        <StoreIcon fontSize='large'/>
                        <div>
                            I own a brick and mortar store and would like to host pop up shops
                        </div>
                    </a>
                    <a className='info-button' href='#merchant-section'>
                        <PointOfSaleIcon fontSize='large'/>
                        <div>
                            I would like to sell my products in physical locations.
                        </div>
                    </a>
                </div>
            </div>
            <div id="merchant-section" className='merchant-section'>
                <div className='panel-holder'>
                    <div className='panel benefit-panel' style={{color: "#393939"}}>
                        <h1> Looking to sell your product in physical stores?</h1>
                        <h2>Partner with Storefronts!</h2>
                        <ul>
                            <li><strong>Expand Your Reach:</strong> Showcase your products in diverse, high-traffic locations.</li>
                            <li><strong>Boost Sales Effortlessly:</strong> Capitalize on additional income streams.</li>
                            <li><strong>Effortless Operations:</strong> Let storefronts manage sales; no need for extra salespeople.</li>
                            <li><strong>Explore New Markets:</strong> Test your products in various locations, reaching different demographics.</li>
                            <li><strong>Elevate Brand Recognition:</strong> Gain visibility in physical spaces, grow brand awareness.</li>
                        </ul>
                        <Button className='signup-button' onClick={() => navigate('/signin')}>Sign up!</Button>
                    </div>
                    <div className='panel'>
                        <div className='panel-back-img'>
                            <div className='info-panel'>
                                <PublishIcon fontSize='large'/>
                                <div style={{fontWeight: '600', fontSize: '18px'}}>Submit an inquiry</div>
                                {/*<p>We'll review your product and reach out to potential storefronts!</p>*/}
                            </div>
                            <div className='info-panel'>
                                <GradingIcon fontSize='large'/>
                                <div style={{fontWeight: '600', fontSize: '18px'}}>Review your matches</div>
                                {/*<p>You'll get a list of storefronts that are interested in selling your product.</p>*/}
                            </div>
                            <div className='info-panel'>
                                <TrendingUpIcon fontSize='large'/>
                                <div style={{fontWeight: '600', fontSize: '18px'}}>Setup your popup!</div>
                                {/*<p>Work with storefront to setup your new popup shop!</p>*/}
                            </div>
                        </div>
                        <img style={{width: "90%", height: '700px', margin: 'auto', marginTop: 0}} src={MerchantImg}/>
                    </div>
                </div>
            </div>
            <div id='storefront-section' className='storefront-section'>
                <div className='panel-holder'>
                    { isMobile &&
                        <StorefrontSection/>
                    }
                    <div className='panel'>
                        <div className='panel-back-img'>
                            <div className='info-panel'>
                                <StorefrontIcon fontSize='large'/>
                                <div style={{fontWeight: '600', fontSize: '18px'}}>Fill out your store profile</div>
                                {/*
                                <p>
                                    We'll need some information about your store to be able to match you with the perfect products to sell.
                                </p>
                                */}
                            </div>
                            <div className='info-panel'>
                                <HandshakeIcon fontSize='large'/>
                                <div style={{fontWeight: '600', fontSize: '18px'}}>Review potential popup shops</div>
                                {/*
                                <p>
                                We'll look for the right products to launch a popup shop in your store. Once we find a match, we'll reach out to you!
                                </p>
                                */}
                            </div>
                            <div className='info-panel'>
                                <SellIcon fontSize='large'/>
                                <div style={{fontWeight: '600', fontSize: '18px'}}>Sell more products</div>
                                {/*
                                <p>
                                    Even after you launch, we'll be in contact and helping every step of the way!
                                </p>
                                */}
                            </div>
                        </div>
                        <img style={{width: "95%", height: '700px', margin: 'auto'}} src={StorefrontImg}/>
                    </div>
                    { !isMobile &&
                        <StorefrontSection/>
                    }
                </div>
            </div>
            <div style={{
                paddingLeft: '10vw',
                paddingRight: '10vw',
                paddingTop: '50px',
                paddingBottom: '50px',
            }}>
                <FaqComponent collapsible={false}/>
            </div>
        </>
    );
};

const StorefrontSection = () => {
    const navigate = useNavigate();
    return (
        <div className='panel benefit-panel' style={{color: "#393939"}}>
        <h1> Have a Physical Store? </h1>
        <h2>Benefits of hosting a Pop-Up</h2>
        <ul>
            <li><strong>Boost Revenue:</strong> Earn additional income effortlessly.</li>
            <li><strong>No Capital Needed:</strong> Sell more without upfront costs or inventory investments.</li>
            <li><strong>Risk-Free Selling:</strong> Pay nothing if products don't sell; no discounts, no losses.</li>
            <li><strong>Increase Foot Traffic:</strong> Attract more visitors to your store.</li>
            <li><strong>Support Local Merchants:</strong> Foster community and support up-and-coming businesses.</li>
            <li><strong>Grow Customer Base:</strong> Expand your reach and enhance customer experiences.</li>
        </ul>
        <Button className='signup-button' onClick={() => navigate('/signin')}>Sign up!</Button>
    </div>
    );
}

export default NewCustomerHomePage;
