

import React, {useState, useEffect} from 'react';

import StorefrontCard from './StorefrontCard';
import { Storefront } from '../../../../types/Storefront';
import CardGrid from './StorefrontGrid';
import './StorefrontGrid.css'; // CSS file for styling the grid
import { FormItem } from '../../../../types/Form';
import {getStorefronts} from '../../../../utils/api/storefront';

type CardSelectProps = {
    label?: string;
    description: string;
    edit: boolean;
    value: FormItem
    onChange?: (value: string[]) => void;
};

const CardSelect: React.FC<CardSelectProps> = ({ label, description, edit, value, onChange }) => {

    const [items, setItems] = useState<Array<Storefront>>([]);
    const [selected, setSelected] = useState<Array<string>>(value.memVal ? value.memVal : []);

    useEffect(() => {
        // Fetch items from the server
        getStorefronts().then((storefronts) => {
            setItems(storefronts);
        });
    }, []);

    function changeSelected(selected: string[]) {
        // TODO: Maintain an internal state and only save if the user clicks done
        onChange && onChange(selected);
        setSelected(selected);
    }
    

    return (
        <div className="grid">
            { edit ? (
                <div>
                    <h3>{label}</h3>
                    <>
                    {items.map(item => (
                        <StorefrontCard
                            key={item.storefrontId}
                            {...item}
                            description={item.description}
                            onSelect={() => {
                                console.log("SELECTED", selected);
                                changeSelected([...selected, item.storefrontId]);
                            }}
                            onUnselect={() => {
                                console.log("SELECTED", selected);
                                changeSelected(selected.filter(id => id !== item.storefrontId));
                            }}
                        />
                    ))}
                    </>
                </div>
            ) : (
                <div>
                    {selected.length == 1 ? (
                        <>
                            {"1 storefront selected"}
                        </>
                    ) : (
                        <>
                            {selected.length + " storefronts selected"}
                        </>
                    )}
                    {" - Change in edit mode"}
                </div>
            )}

        </div>
    );
};

export default CardSelect;