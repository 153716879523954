const PrivacyPage: React.FC<{
}> = () => {
    return (
        <div className='inv-container'
            style={{
                paddingTop: 40,
                paddingLeft: 120,
                paddingRight: 120,
            }}>
            <h1>Privacy Policy</h1>

            <p>Effective Date: 01/01/2024</p>

            <p>This Privacy Policy describes how nookc.com ("we", "our", or "us") collects, uses, and shares information about you through our online interfaces (e.g., websites and mobile applications) owned and controlled by us, including <a href="http://www.nookc.com">www.nookc.com</a> (collectively referred to herein as the “Site”). Please read this notice carefully to understand what we do. If you do not understand any aspects of our Privacy Policy, please feel free to contact us at support@nookc.com.</p>

            <h2>Information We Collect</h2>
            <p>We collect information about you when you provide it to us, when you use our Services, and when other sources provide it to us, as further described below.</p>
            <ul>
                <li><strong>Information You Provide to Us:</strong> We receive and store any information you enter on our Site or provide to us in some other manner. This includes information that can identify you (“personal information”), including your first and last name, telephone number, postal and email addresses, user name and password, and billing information (such as your credit card number, cardholder name, and card expiration date).</li>
                <li><strong>Information from Other Sources:</strong> We may also periodically obtain both personal and non-personal information about you from affiliated entities, business partners and other independent third-party sources and add it to our account information. Examples of information we may receive include updated delivery and address information, purchase history, and demographic information.</li>
            </ul>

            <h2>How We Use Your Information</h2>
            <p>We use sensitive billing information (such as cardholder name, credit card number, and expiration date) for the purpose of completing the purchases you conduct on our site. We use other information about you for the following general purposes: to provide you with the products and services you request, to provide you with booking confirmation and updates, to manage your account, to communicate with you in general, to answer your questions and comments, to measure interest in and improve our products, services, and Site, to notify you about special offers and products or services available from us or our partners that may be of interest to you, to resolve disputes, troubleshoot problems, to prevent potentially prohibited or illegal activities, and to enforce our Terms of Service.</p>

            <h2>How We Share Your Information</h2>
            <p>With Storefront Partners: We may share your information with the Storefront Partners you engage with on our Site, to facilitate the services they provide to you.</p>
            <p>With Third Parties: We may share your information with third-party vendors who provide services or functions on our behalf, including credit card processing, business analytics, customer service, marketing, distribution of surveys or sweepstakes programs, and fraud prevention. We may also authorize third-party vendors to collect information on our behalf, including as necessary to operate features of our Site or to facilitate the delivery of online advertising tailored to your interests.</p>
            <p>As permitted by law: We may also share your information when required by law, such as in response to a subpoena, including to law enforcement agencies and courts in the countries where we operate.</p>

            <h2>Your Choices and Rights</h2>
            <p>You have rights over how your personal information is used including:</p>
            <ul>
                <li>The right to object to our processing of your data.</li>
                <li>The right to request that your information be erased or restricted from further use.</li>
                <li>The right to request a copy of the information we hold about you.</li>
                <li>The right to correct, amend, or update information you have given us (where you have an account with us you can also do this by logging in and updating your information).</li>
            </ul>
            <p>To ask for your information to be amended or deleted, please contact support@nookc.com.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may change or modify this Privacy Policy in the future. We will note the date that revisions were last made to this Privacy Policy at the top of this page, and any revisions will take effect upon posting</p>
       </div>
    )
}

export default PrivacyPage