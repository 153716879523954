
import {BaseApiUrl, getAuthHeader} from './base'



// Path: web-app/src/utils/google-places/index.ts
export async function searchPlaces(textquery: string): Promise<google.maps.places.PlaceResult[]> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/utils/google-places?query=${textquery}`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }
        }).then(
            response => response.json()
        ).then((data) => {
            return data.places;
        });
    });
}

export async function getPlaceDetails(placeId: string): Promise<google.maps.places.PlaceResult & {editorial_summary: {langage: string, overview: string}}> {
    return getAuthHeader().then((token) => {
        if (token === undefined) {
            throw new Error("Unauthenticated")
        }
        return fetch(`${BaseApiUrl}/utils/google-places/details?placeId=${placeId}`, {
            method: 'GET',
            headers: {
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            }
        }).then(
            response => response.json()
        ).then((data) => {
            return data.place;
        });
    });
}