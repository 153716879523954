
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Account, AccountType } from '../../types/Account';
import { AccountBalance } from '@mui/icons-material';
import { useManageContext } from '../../utils/context/manageContext';


enum ResponseStatus {
    None = 'none',
    Success = 'success',
    Error = 'error'
}

const AccountEditable: React.FC<Account & {onCancel?: () => void}> = ({ id, name, email, accountType, organization, onCancel }) => {
    const [accountTypeEdit, setAccountTypeEdit] = useState<AccountType>(accountType as AccountType);
    const [organizationNameEdit, setOrganizationNameEdit] = useState<string>(organization);
    const [responseStatus, setResponseStatus] = useState<ResponseStatus>(ResponseStatus.None);
    const { updateAccount } = useManageContext();

    const isMobile = window.innerWidth < 600;
    const internalWidth = isMobile ? '90%' : '30rem';

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        updateAccount({
            id: id,
            orgType: accountTypeEdit,
            organization: organizationNameEdit
        }).then((accountInfo) => {
            onCancel && onCancel();
            setResponseStatus(ResponseStatus.Success);
        }).catch((error) => {
            setResponseStatus(ResponseStatus.Error);
        });
    }

    return (
        <Box component="form" onSubmit={handleSubmit} style={{
            width: internalWidth, margin: 'auto', marginTop: '10vh', border: 'solid',
            borderColor: '#89949f', borderWidth: '1px', padding: '2rem', borderRadius: '5px',
        }}>
            <div style={{fontSize: 30, fontWeight: 500, marginBottom: 15}}>
                Account Setup
            </div>
            <div style={{ marginBottom: 15}}>Name: <b>{name}</b></div>
            <div style={{ marginBottom: 15}}>Email: <b>{email}</b></div>
            <div>What type of business are you setting up?</div>
            <FormControl fullWidth margin="normal" style={{marginTop: 5, marginBottom: 15}}>
                <InputLabel id="account-type-label">Business Type</InputLabel>
                <Select
                    labelId="account-type-label"
                    id="account-type"
                    value={accountTypeEdit}
                    label="AccountType"
                    onChange={(e: SelectChangeEvent) => {
                        console.log(e.target.value)
                        setAccountTypeEdit(e.target.value.toLowerCase() as AccountType)
                    }}
                >
                    <MenuItem value={AccountType.maker}>
                        {"Maker (I want to sell my product in a physical store)."}
                    </MenuItem>
                    <MenuItem value={AccountType.storefront}>
                        {"Storefront (I own a physical store and want to sell more items)."}
                    </MenuItem>
                </Select>
            </FormControl>
            <div>What's the name of your business?</div>
            <TextField
                margin="normal"
                fullWidth
                id="organization-name"
                label="Business Name"
                value={organizationNameEdit}
                onChange={(e) => setOrganizationNameEdit(e.target.value)}
                style={{marginTop: 5, marginBottom: 15}}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                style={{
                    backgroundColor: '#047d95',
                }}
                sx={{ mt: 3, mb: 2 }}
            >
                Submit
            </Button>
            { responseStatus === ResponseStatus.Success && (
                <div style={{color: 'green'}}>
                    Success!
                </div>
            )}
            { responseStatus === ResponseStatus.Error && (
                <div style={{color: 'red'}}>
                    Error!
                </div>
            )}
        </Box>
    );
};

export default AccountEditable;
