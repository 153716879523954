
import React from 'react'
import FaqComponent from '../../components/homepage-components/Faq' 

const FaqPage: React.FC<{
}> = () => {
    return (
        <div className='inv-container'
            style={{
                paddingTop: 40,
                paddingLeft: 120,
                paddingRight: 120,
            }}>
            <FaqComponent collapsible={false}/>
       </div>
    )
}

export default FaqPage