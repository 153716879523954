import React, {useState} from 'react';
import AccountComponent from '../../components/account';
import { useNavigate } from 'react-router-dom';
import {AccountStatus, useManageContext} from '../../utils/context/manageContext';


type AccountPageProps = {
}

const AccountPage: React.FC<AccountPageProps> = () => {
    const [isEditing, setIsEditing] = useState(false);
    const { accountInfo } = useManageContext();
    const navigate = useNavigate();

    return (
        <div className="page-container" >
            { accountInfo == AccountStatus.NotLoggedIn && (
                <div style={{padding: '70px'}}>
                    You're not logged in! Please log in to view your account.
                    <button onClick={()=>navigate('/signin')}>
                        Sign In
                    </button>
                </div>
            )}
            { accountInfo == AccountStatus.NotLoaded && (
                <div style={{padding: '70px'}}>
                    Loading...
                </div>
            )}
            { accountInfo !== AccountStatus.NotLoggedIn && accountInfo !== AccountStatus.NotLoaded && (
                <AccountComponent
                    accountInfo={accountInfo}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </div>
    );
};

export default AccountPage;
