
import React, {useState} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const FaqComponent: React.FC<{
    collapsible: boolean,
}> = ({
    collapsible,
}) => {
    const [open, setOpen] = useState(!collapsible);
    console.log(collapsible);
    return (
        <div style={{
            textAlign: 'left',
        }}>
            <h1 style={{fontWeight: 500, fontSize: 35, marginBottom: 25}}>
                Frequently Asked Questions
                { collapsible && (
                    <>
                    {open && <ArrowDropUpIcon style={{cursor: 'pointer'}} onClick={() => setOpen(false)}/>
                    || <ArrowDropDownIcon style={{cursor: 'pointer'}} onClick={() => setOpen(true)}/>}
                    </>
                )}
            </h1>
            {(!collapsible || open) && (
                <>
                <h2>How much does NookC charge?</h2>
                <p>NookC charges nothing to sign up. Once successfully matched with a store/maker, NookC charges a $50 matching fee.</p>
                <h2>What percentage split between the store and maker?</h2>
                <p>NookC will assist in negotiating the consignment percentage split between the maker and store. This percentage may vary based on the item's price point and the store’s space/location.</p>
                <h2>How long does the pop-up last?</h2>
                <p>Pop-ups typically last from 2 weeks to 3 months. Your pop-up length preference can be stated when you fill out your profile.</p>
                <h2>Who is responsible for replenishing the inventory?</h2>
                <p>The maker is responsible for mailing or dropping off the inventory to the store.</p>
                <h2>How do I sign up?</h2>
                <p>You can fill out an application <a href='/profile'>here</a>.</p>
                <h2>What criteria does NookC use to match stores with makers?</h2>
                <p>NookC matches stores with makers based on various factors including the type of products, target audience, and geographical location to ensure compatibility and maximize success.</p>
                </>
            )}
        </div>
    )
}

export default FaqComponent;