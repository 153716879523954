
import { set } from 'date-fns';
import React, {Component, useState} from 'react';
import { DateRangePicker, Range } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
  } from 'date-fns';

interface DateRangeInputProps {
    value: string|undefined,
    onChange: (value: string|undefined) => void,
}

interface DateRangeInputState {
    range: Range;
}

const DEFAULT_RANGE: Range = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
};

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  };

export class DateRangeInput extends Component<DateRangeInputProps, DateRangeInputState> {

    constructor(props: DateRangeInputProps) {
        super(props);
        this.state = {
          range: this.stringToRange(props.value),
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(ranges: any) {
        this.props.onChange(this.rangeToString(ranges.selection));
        this.setState({
            range: ranges.selection,
        });
    }

    rangeToString(range: Range) {
        if (range.startDate === undefined || range.endDate === undefined) {
            return '';
        }
        return range.startDate.toDateString() + '/' + range.endDate.toDateString();
    }

    stringToRange(value: string|undefined): Range {
        console.log('stringToRange', value);
        if (value === undefined || value === null || typeof value !== 'string' || !value.includes('/')) {
            return DEFAULT_RANGE;
        }

        let parts = value.split('/');
        let startDate = new Date(parts[0]);
        let endDate = new Date(parts[1]);
        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
            return DEFAULT_RANGE;
        }
        return {
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
        };
    }

    render() {
        console.log('DateRangeInput render', this.state.range);

        return (
            <div>
                <DateRangePicker
                    showDateDisplay={false}
                    ranges={[this.state.range]}
                    onChange={this.handleSelect}
                    staticRanges={[]}
                    inputRanges={[]}
                    // Add more props as needed
                />
            </div>
        );
    }
}
