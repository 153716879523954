const TosPage: React.FC<{
}> = () => {
    return (
        <div className='inv-container'
            style={{
                paddingTop: 40,
                paddingLeft: 120,
                paddingRight: 120,
            }}>
            <h1>Terms of Service</h1>

            <h2>1. Introduction</h2>
            <p>Welcome to nookc.com, a digital platform dedicated to fostering consignment partnerships between storefronts and online businesses. By accessing or using our website, services, and tools (collectively, "Services"), you agree to comply with and be bound by the following terms and conditions (the "Terms of Service").</p>

            <h2>2. Services Description</h2>
            <p>nookc.com provides an online platform that enables registered storefronts ("Storefront Partners") and online businesses ("Online Partners") to enter into consignment agreements whereby Online Partners can sell their products through physical locations operated by Storefront Partners.</p>

            <h2>3. User Registration</h2>
            <p>To use certain features of the Services, you must register for an account ("Account") and provide certain information about yourself as prompted by the registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information.</p>

            <h2>4. Privacy Policy</h2>
            <p>Our Privacy Policy, which describes how we handle data you provide to us, is accessible at <a href="http://nookc.com/privacy">nookc.com/privacy</a>. By using our Services, you agree to our use, collection, and disclosure of personally identifiable information in accordance with the Privacy Policy.</p>

            <h2>5. User Responsibilities</h2>
            <p>You are responsible for all activities that occur under your Account. You agree to: (a) immediately notify us of any unauthorized use of your Account or any other breaches of security, and (b) ensure that you exit from your Account at the end of each session.</p>

            <h2>6. Content Ownership and Use Rights</h2>
            <p>The content provided through the Services, including all written material, graphics, logos, software, and trademarks, is the property of nookc.com or its licensors and is protected by copyright and intellectual property laws. You may not use, reproduce, distribute, display, or perform any content not explicitly permitted by these Terms of Service.</p>

            <h2>7. Termination and Suspension</h2>
            <p>We may terminate or suspend your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms of Service.</p>

            <h2>8. Limitation of Liability</h2>
            <p>In no event will nookc.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.</p>

            <h2>9. Governing Law</h2>
            <p>These Terms shall be governed and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions.</p>

            <h2>10. Changes</h2>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Services after those revisions become effective, you agree to be bound by the revised terms.</p>

            <h2>11. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at support@nookc.com.</p>
       </div>
    )
}

export default TosPage