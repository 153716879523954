import React, {useEffect} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';


type SocialMedia = {
    fb: string,
    insta: string,
    other: string
}

const SocialMediaInput: React.FC<{
  value: string|undefined,
  onChange: (value: string|undefined) => void,
}> = ({value, onChange}) => {

    const [socialMedia, setSocialMedia] = React.useState<SocialMedia>({
        fb: '',
        insta: '',
        other: ''
    });

    useEffect(() => {
        if (value) {
            try {
                const socialMedia: SocialMedia = JSON.parse(value);
                setSocialMedia(socialMedia);
            } catch (e) {}
        }
    }, [value]);

    function handleInputChangeInternal(newSocialMedia: Partial<SocialMedia>) {
        onChange(JSON.stringify({
            ...socialMedia,
            ...newSocialMedia
        }));
    }

    return (
        <div>
        <input
            placeholder="Facebook"
            value={socialMedia.fb}
            onChange={(e) => handleInputChangeInternal({fb: e.target.value})}
            className= 'text-input'
        />
        <input
            placeholder="Instagram"
            value={socialMedia.insta}
            onChange={(e) => handleInputChangeInternal({insta: e.target.value})}
            className= 'text-input'
        />
        <input
            placeholder="Other"
            value={socialMedia.other}
            onChange={(e) => handleInputChangeInternal({other: e.target.value})}
            className= 'text-input'
        />
        </div>
  );
}

export const SocialMediaPreview: React.FC<{
    value: string|undefined,
  }> = ({value}) => {
    let asObj: SocialMedia = {
        fb: '',
        insta: '',
        other: ''
    };

    if (value) {
        try {
            asObj = JSON.parse(value);
        } catch (e) {}
    }
    const asList = Object.values(asObj).filter((v) => v.length > 0);
    const asString = asList.length > 0 ? asList.join(', ') : <i> Not Set </i>;
    return (
        <>
            {asString}
        </>
    )
}

export default SocialMediaInput;
