

import React, { useState, useEffect, useRef } from 'react';

import SectionedForm, {SectionFormHeader} from './sectioned';
import { Form, FormUpdater } from '../../types/Form';
import FormPreview from './preview';
import FormDoc from '.';
import FormHeader from './header';
import './form.css'
import './buttons.css'
import '../container.css'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export type FormListProps = {
    formList: Form[],
    title?: string,
    width?: string,
    onNew: () => void,
    onUpdate: (form: FormUpdater) => void,
    onRemove: (form: FormUpdater) => void,
    onSelect: (form: Form) => void,
    onCancel?: () => void,
};

const FormListComponent: React.FC<FormListProps> = ({
    formList,
    title,
    onNew,
    onUpdate,
    onRemove,
    onSelect,
    onCancel,
}) => {
    const [selectedForm, setSelectedForm] = useState<Form | null>(null);

    function onSelectForm(form: Form) {
        setSelectedForm(form);
        onSelect(form);
    }

    if (selectedForm) {
        return (
            <FormDoc form={selectedForm} onSave={onUpdate} onCancel={() => {
                setSelectedForm(null);
                onCancel && onCancel();
            }}/>
        )
    }
    return (
        <>
        { title && <FormHeader formTitle={title} />}
        { formList.map(form => {
            return (
                <FormPreview form={form} key={form.name} onSelect={() => onSelectForm(form)}>
                    <button className='form-button' onClick={() => {
                        onSelectForm(form);
                    }}
                    style={{
                        width: 120,
                        display: 'inline-block'
                    }}>
                        edit
                    </button>
                    <button className='form-button' onClick={() => onRemove(form)} style={{width: 120, display: 'inline-block'}}> remove </button>
                </FormPreview>
            )
        })}
        <button className='form-button'  onClick={onNew}> Add a product </button>
        </>
    )
}

export default FormListComponent;