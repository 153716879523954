import React, { useState, useEffect, useRef } from 'react';

import FormInput from './input/FormInput';
import { FormItem, FormItemValue } from '../../types/Form';
import './form.css'

export type FormProps = {
    items: FormItem[],
    editMode?: boolean,
    cancelText?: string,
    saveText?: string,
    onSave?: (newItems: FormItem[]) => void,
    onCancel: (newItems: FormItem[]) => void,
};

const SectionedForm: React.FC<FormProps> = ({
    items,
    editMode,
    cancelText,
    saveText,
    onSave,
    onCancel
}) => {

    const visibleItems = items.filter((i) => i.visible);
    const [formState, setFormState] = useState<FormItem[]>(visibleItems);


    const handleInputChange = (item: FormItemValue, value: any) => {
        if (value !== undefined) {
            let memValChange;
            if (editMode) {
                memValChange = [...items];
                let itemIndex = memValChange.findIndex((i) => i.name === item.name);
                memValChange[itemIndex].memVal = value;
                setFormState(memValChange);
            }
        }
    };

    return (
        <>
            { visibleItems.map((item, index) => {
                return (
                    <div key={index}>
                        <FormInput
                            item={item}
                            editMode={editMode}
                            handleInputChange={(onSave !== undefined) ? handleInputChange : undefined}
                        />
                    </div>
                )
            })}
            { (editMode && onSave !== undefined) && (
                <div className='form-button-holder'>
                    <button className='form-back-button' onClick={() => onCancel(formState)}>{cancelText ? cancelText : "Cancel"}</button>
                    <button className='form-next-button' onClick={() => onSave(formState)}>{saveText ? saveText : "Save"}</button>
                </div>
            )}
        </>
    )
}

export const SectionFormHeader: React.FC<{
    title?: string,
    editMode: boolean,
    exitEditMode: () => void,
    enterEditMode: () => void,
}> = ({title, editMode, exitEditMode, enterEditMode}) => {
    return (
        <div style={{marginBottom: "10px"}}>
            { title &&
                <h2 style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    display: 'inline',
                }}>
                    {title}
                </h2>
            }
            { editMode ? (
                <button className='cancel-button'
                    style={{
                        marginLeft: '30px',
                        height: '30px'
                    }}
                    onClick={exitEditMode}>
                    X
                </button>
            ) : (
                <>
                {" - "}
                <button className='link-button'
                    style={{fontSize: '20px'}}
                    onClick={enterEditMode}>
                    Edit
                </button>
                </>
            )}
        </div>
    )
}

export default SectionedForm;