
import React, { useState, useEffect, useRef } from 'react';

import SectionedForm, {SectionFormHeader} from './sectioned';
import { Form, FormUpdater, FormItem, FormItemValue, Section } from '../../types/Form';
import FormHeader from './header';
import {getFormStyle} from './utils';
import './form.css'
import './buttons.css'
import '../container.css'


export type FormProps = {
    form: Form,
    width?: string,
    editMode?: boolean,
    header?: boolean,
    onEditModeChange?: (editMode: boolean) => void,
    onSave?: (form: FormUpdater) => void,
    onCancel?: () => void,
    onSubmit?: () => void,
};

const FormDoc: React.FC<FormProps> = ({
    form,
    width,
    editMode: editModeProp,
    header,
    onEditModeChange,
    onSave,
    onCancel,
    onSubmit,
}) => {

    header = header === undefined ? true : header;
    editModeProp = editModeProp? editModeProp : false;
    const editModeInit = form.sections?.length == 0 ? true : false;
    const [editMode, setEditMode] = useState<boolean>(editModeInit || editModeProp);
    const [currentSection, setCurrentSection] = useState<number>(0);

    useEffect(() => {
        if (!editMode && form.sections?.length == 0 && onCancel) {
            onCancel();
        }
        if (onEditModeChange) {
            onEditModeChange(editMode);
        }
    }, [editMode]);
    

    const isMobile = window.innerWidth < 600;
    let defaultInternalWidth = isMobile ? '100%' : '100%';
    let internalWidth = width ? width : defaultInternalWidth;
    let sections: Section[] = [{
        start: 0,
        end: form.items.length
    }];

    if (form.sections && form.sections.length > 0) {
        sections = form.sections
    };
    let lastSection = sections.length - 1;

    function prevSection() {
        if (currentSection > 0) {
            setCurrentSection(currentSection - 1);
        } else {
            setEditMode(false);
        }
    }

    function nextSection() {
        if (currentSection < lastSection) {
            setCurrentSection(currentSection + 1);
        } else {
            setEditMode(false);
        }
    }

    function onSaveInner(newItems: FormItemValue[]) {
        if (!onSave) {
            return;
        }
        onSave({
            id: form.id,
            items: newItems
        });
    }

    let onSaveSectionedForm = (onSave !== undefined) ? (newItems: FormItem[]) => {
        onSaveInner(newItems);
        nextSection();
    } : undefined;

    let formStyle: {
        width: string,
        height?: string,
        overflow?: string,
    } = {
        width: internalWidth
    }

    if (editMode) {
        formStyle = getFormStyle(formStyle)
    }
    return (
        <>
            <div className='form inv-container' style={formStyle}>
                    { (editMode) ? (
                        <EditingFormHeader
                            sectionTitle={sections[currentSection].name}
                            onExit={() => setEditMode(false)}
                        />
                    ) : (
                        <>
                        { header && <FormHeader formTitle={form.name} completed={form.completed}
                            onCancel={onCancel} onSubmit={onSubmit}/>
                        }
                        </>
                    )}
                    <div>
                    { sections.map((section, index) => {
                        return (
                            <div key={`form-section-${index}` }>
                            { (currentSection === index || !editMode) &&
                                <>
                                { !editMode &&
                                    <SectionFormHeader
                                        title={section.name}
                                        editMode={editMode}
                                        exitEditMode={() => setEditMode(false)}
                                        enterEditMode={() => {
                                            setCurrentSection(index);
                                            setEditMode(true)
                                        }}
                                    />
                                }
                                <SectionedForm
                                    key={index}
                                    items={form.items.slice(section.start, section.end)}
                                    editMode={editMode}
                                    onCancel={(newItems) => {
                                        onSaveInner(newItems);
                                        prevSection();
                                    }}
                                    onSave={onSaveSectionedForm}
                                    cancelText={index === 0 ? "Cancel" : "Back"}
                                    saveText={index === lastSection ? "Save" : "Save and Continue"}
                                />
                                </>
                            }
                            { (!editMode && index !== lastSection) &&
                                <div style={{
                                    width: '100%',
                                    border: '.5px solid silver',
                                    marginTop: '30px',
                                    marginBottom: '5px',
                                }}/>
                            }
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
};



export const EditingFormHeader: React.FC<{
    sectionTitle: string|undefined,
    onExit: () => void,
}> = ({sectionTitle, onExit}) => {
    return (
        <div className='container-header horizontal'>
            <h1 style={{display: 'inline'}}>
                {sectionTitle ? sectionTitle : 'Edit Section'}
            </h1>
        
            <button
                className='white-rounded'
                onClick={onExit}
                style={{width: '100px', height: '40px', fontSize: '20px'}}>
                    Exit
            </button>
    </div>
    )
}

export default FormDoc;
