
export enum AccountType {
    storefront = "storefront",
    maker = "maker",
}

export type Account = {
    id: string;
    name: string;
    email: string;
    accountType: string;
    organization: string;
    completed: boolean;
};
