import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {signOut } from 'aws-amplify/auth'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

import {AccountStatus, useManageContext} from '../../utils/context/manageContext';

import { Account } from '../../types/Account';


import './Header.css'; // Assuming you will have a CSS file for styling
import { Button } from 'react-bootstrap';
 
interface HeaderProps {
}

const Header: React.FC<HeaderProps> = () => {
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const { accountInfo, resetAccount } = useManageContext();
    const navigate = useNavigate();
    const dropdownButtonRef = useRef<HTMLDivElement>(null);

    const toggleProfileDropdown = () => {
        setIsProfileDropdownOpen(!isProfileDropdownOpen);
    };

    async function signOutUser() {
        console.log("signing out")
        try {
            resetAccount();
                signOut().then(() => {
                navigate('/');
            });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    
    return (
        <>
            <header className="header">
                <Link className="header-logo" to="/">
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.5em',
                        fontWeight: 500,
                    }}>
                    <img src={'/logo512.png'} alt="Logo" style={{
                        borderRadius: '10%',
                        marginRight: '20px'
                    }}/>
                    NookC
                    </div>
                </Link>
                <div className="header-profile">
                    <div className="account-button" onClick={toggleProfileDropdown} ref={dropdownButtonRef}>
                        <MenuIcon fontSize='large' className='grey-icon' />
                        <AccountCircleIcon fontSize='large' className='grey-icon' />
                    </div>
                </div>
            </header>
            {isProfileDropdownOpen && (
                <ProfileDropdown
                    account={accountInfo}
                    dropdownButtonRef={dropdownButtonRef}
                    signOutUser={signOutUser}
                    exitProfileDropdown={()=>setIsProfileDropdownOpen(false)}
                />
            )}
        </>
    );
};

const ProfileDropdown: React.FC<{
    account: Account|AccountStatus,
    dropdownButtonRef: React.RefObject<HTMLDivElement>,
    exitProfileDropdown: () => void,
    signOutUser: () => Promise<void>,
}> = ({account, dropdownButtonRef, exitProfileDropdown, signOutUser}) => {

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            let eventNode = event.target as Node;
            if (!dropdownRef.current?.contains(eventNode)
                    && !dropdownButtonRef.current?.contains(eventNode)) {
                // Action to take on click outside
                exitProfileDropdown()
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]); // Dependencies array for useEffect

    return (
        <div className="profile-dropdown" ref={dropdownRef}>
            { (account === AccountStatus.NotLoaded || account === AccountStatus.NotLoggedIn) ? (
                <ProfileDropdownAccountStatus accountStatus={account as AccountStatus}/>
            ) : (
                <>
                    <div style={{marginBottom: '3px'}}>
                        <Link to='/account'>
                            Account
                        </Link>
                    </div>
                    <div style={{marginBottom: '3px'}}>
                        <Link to='/profile'>
                            Organization
                        </Link>
                    </div>
                    <div onClick={signOutUser} style={{cursor: 'pointer'}}>Logout</div>
                </>
            )}
        </div>
    )
}

const ProfileDropdownAccountStatus: React.FC<{
    accountStatus: AccountStatus
}> = ({accountStatus}) => {
    const navigate = useNavigate();
    console.log(accountStatus === AccountStatus.NotLoaded);
    console.log(accountStatus === AccountStatus.NotLoggedIn)
    return (
        <>
            { (accountStatus === AccountStatus.NotLoaded) ? (
                <>
                loading...
                </>
            ) : (
                <div onClick={() => navigate('/signin')}
                    style={{cursor: 'pointer'}}>
                        Login
                </div>
            )}
        </>
    )
}

export default Header;
