import React, { useState, useEffect } from 'react';
import { getOperationsInfo, getAccountInfo } from '../../utils/api/operations';

import './OperationsPage.css'; // CSS file for styling the profile page
import { Organization } from '../../types/Organization';
import { Button, Dropdown } from 'react-bootstrap';
import { Account } from '../../types/Account';
import { Form } from '../../types/Form';
import FormDoc from '../../components/form';
import { updateAccountInfo } from '../../utils/api/account';
import { OrganizationType } from '../../types/Organization';


type OrgViewProps = {
    orgs: Organization[]
    setOrgs: (orgs: Organization[]) => void
}

enum FilterType {
    All = 'All',
}

enum ProfileStatus {
    NotStarted = 'Not Started',
    Started = 'Started',
    Complete = 'Complete',
}


const OrgView: React.FC<OrgViewProps> = ({orgs, setOrgs}) => {

    const [accountTypeFilter, setAccountTypeFilter] = useState<FilterType | OrganizationType>(FilterType.All)
    const [statusFilter, setStatusFilter] = useState<FilterType | ProfileStatus>(FilterType.All)
    const [showForm, setShowForm] = useState<Form|null>(null);

    useEffect(() => {
        getOperationsInfo().then((response) => {
            console.log(response);
            setOrgs(response.organizations);
        });
    }, []);

    function getOrgStatus(org: Organization): ProfileStatus {
        if (!org.startedForm) {
            return ProfileStatus.NotStarted;
        }
        if (org.orgType === OrganizationType.maker && org.shop.completed) {
            return ProfileStatus.Complete;
        } else if (org.orgType === OrganizationType.storefront && org.storefront.completed) {
            return ProfileStatus.Complete;
        }

        return ProfileStatus.Started;
    }


    return (
        <div style={{
            paddingTop: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            { showForm === null ? (
                <>
                    <div style={{display: "flex", alignItems: 'center'}}>
                        Filter Orgs:
                        <div>
                            <Dropdown>
                                
                                {accountTypeFilter == FilterType.All ?
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Type
                                    </Dropdown.Toggle> : 
                                    <Dropdown.Toggle variant='outline-success' id="dropdown-basic" style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderColor: 'black',
                                    }}>
                                        {accountTypeFilter}
                                    </Dropdown.Toggle> 
                                }
                            
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setAccountTypeFilter(FilterType.All)}>{FilterType.All}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setAccountTypeFilter(OrganizationType.maker)}>{OrganizationType.maker}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setAccountTypeFilter(OrganizationType.storefront)}>{OrganizationType.storefront}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div>
                            <Dropdown>
                                {statusFilter == FilterType.All ?
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        Status
                                    </Dropdown.Toggle> : 
                                    <Dropdown.Toggle variant='outline-success' id="dropdown-basic" style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderColor: 'black',
                                    }}>
                                        {statusFilter}
                                    </Dropdown.Toggle> 
                                }
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setStatusFilter(FilterType.All)}>{FilterType.All}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setStatusFilter(ProfileStatus.NotStarted)}>{ProfileStatus.NotStarted}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setStatusFilter(ProfileStatus.Started)}>{ProfileStatus.Started}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setStatusFilter(ProfileStatus.Complete)}>{ProfileStatus.Complete}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        { orgs.map((org) => {
                            let status = getOrgStatus(org);
                            if ((accountTypeFilter === FilterType.All || org.orgType === accountTypeFilter) &&
                                (statusFilter === FilterType.All || status === statusFilter)) {
                                return <OrganizationPreview
                                    organization={org}
                                    status={status}
                                    key={org.name}
                                    onClickForm={setShowForm}/>
                            }
                        })}
                    </div>
                </>
            ) : (
                <div className='page-container'>
                    <Button onClick={() => setShowForm(null)}>Back</Button>
                    <FormDoc form={showForm} />
                </div>
            )}
        </div>
    );
};

const OrganizationPreview: React.FC<{
    organization: Organization,
    status: ProfileStatus,
    onClickForm: (form: Form) => void
}> = ({organization, status, onClickForm}) => {
    const [showAccount, setShowAccount] = useState<string|null>(null);
    const [account, setAccount] = useState<Account|null>(null);
    const [editAccountType, setEditAccountType] = useState<boolean>(false);

    useEffect(() => {
        if (showAccount !== null && (account === null || account.email !== showAccount)) {
            getAccountInfo(showAccount).then((response) => {
                setAccount(response.account);
            });
        }
    }, [showAccount]);

    function onSubmitAccountType() {
        if (account === null) {
            return;
        }
        updateAccountInfo({
            id: account.id,
            accountType: account.accountType
        }, account.id).then((response) => {
            setEditAccountType(false);
        });
    }

    return (
        <div style={{
            height: 200,
            width: 400,
            border: '1px solid black',
            borderRadius: 10,
            margin: 10,
            textAlign: 'left',
            padding: '5px 20px',
            overflow: 'scroll'
        }}>
            {(showAccount === null) ? (
                <>
                    <div style={{marginTop: 10, marginBottom: 10}}>
                        <b>{organization.name}</b> - <i>{status}</i>
                    </div>
                    
                    <div>
                    <h4 style={{margin:1}}>Admins</h4>
                        {organization.administrators.map((admin_email) => {
                            return (
                                <div className='account-name-link'
                                    onClick={() => setShowAccount(admin_email)}>
                                    {admin_email}
                                </div>
                            )
                        })}
                    </div>
                    <h4 style={{margin:1}}>Forms</h4>
                    { organization.orgType === OrganizationType.maker && (
                        <div>
                            <Button className='link-button' onClick={() => onClickForm(organization.shop)}>Maker</Button>
                            <h4 style={{margin:1}}>Products</h4>
                            { organization.shopProducts.map((product) => {
                                return (
                                    <div>
                                        <Button className='link-button' onClick={() => onClickForm(product)}>{product.preview?.name}</Button>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                    { organization.orgType === OrganizationType.storefront && (
                        <div>
                            <Button  className='link-button' onClick={() => onClickForm(organization.storefront)}>Storefront</Button>
                        </div>
                    )}
                </>
            ) : (
                <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10, marginBottom: 10}}>
                        <div>
                            <b>
                            Account Details
                            </b>
                        </div>
                        <div>
                            <button onClick={() => setShowAccount(null)}>Close</button>
                        </div>
                    </div>
                    <div>
                        <b>Name:</b>{" "}{account?.name}
                    </div>
                    <div>
                        { editAccountType ? (
                            <>
                            <input type='text' value={account?.accountType} onChange={(e) => {
                                if (account !== null) {
                                    setAccount({
                                        ...account,
                                        accountType: e.target.value
                                    });
                                }
                            }} />
                            {" "}
                            <button className='link-button' onClick={() => setEditAccountType(false)}>Cancel</button>
                            {" "}
                            <button className='link-button' onClick={onSubmitAccountType}>Submit</button>
                            </>
                        ) : (
                            <div>
                                <b>Account Type:</b>{" "}{account?.accountType}
                                {" "}
                                <button className='link-button' onClick={() => setEditAccountType(true)}>Edit</button>
                            </div>
                        )}
                    </div>
                    <div>
                        <b>Email:</b>{" "}{account?.email}
                    </div>
                    <div>
                        <b>Account Completed:</b>{" "}{account?.completed ? "Completed" : "Incomplete"}
                    </div>
                </div>
            )}
        </div>
    );
}

export default OrgView;
