

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function GreenCheckmark() {
  return <CheckCircleIcon sx={{ color: '#b8d8be' }} />;
}

const FormHeader: React.FC<{
    formTitle?: string,
    completed?: boolean,
    onSubmit?: () => void,
    onCancel?: () => void,
}> = ({formTitle, completed, onSubmit, onCancel}) => {
    return (
        <div className='container-header'>
        { formTitle !== undefined && (
                <h1 style={{display: 'inline'}}>
                    {formTitle}
                </h1>
        )}
        <div style={{
            alignItems: "center",
            display: "inline-flex",
            color: 'grey',
            fontSize: '25px',
            marginLeft: '10px',
            }}>

            { onCancel ? ( 
                <button className='form-button aligned' onClick={onCancel}>Go Back</button>
            ) : (<></>)
            } 
            { onSubmit ? (
                <button className='form-button aligned' onClick={onSubmit}>Submit</button>
            ) : (<></>)
            }
            { completed && 
                <>
                Completed
                <GreenCheckmark/>
                </>
            }
        </div>
    </div>
    )
}

export default FormHeader;