import React, {ReactNode, useState, useContext} from 'react';
import {getAccountInfo, updateAccountInfo, AccountResponse} from '../../utils/api/account';
import { Account } from '../../types/Account';
import { Organization } from '../../types/Organization';
import { fetchAuthSession } from 'aws-amplify/auth';

import { JWT } from 'aws-amplify/auth';

type ManageContextState = {
    accountInfo: Account|AccountStatus,
    orgInfo: Organization|null,
    setOrgInfo: (orgInfo: Organization|null) => void,
    userId: string,
    userName: string,
    setUserId: (userId: string) => void,
    setUserName: (userName: string) => void,

    updateAccount: (accountInfo: Partial<Account> & {orgType: string}) =>  Promise<Account>,
    getAccount: (forceReload?: boolean) => Promise<Account|AccountStatus>
    resetAccount: () => void
}

enum AccountStatus {
    NotLoaded,
    NotLoggedIn
}

const ManageContext = React.createContext<ManageContextState|undefined>(undefined);


interface ManageProviderProps {
    children: ReactNode;
}

const ManageProvider: React.FC<ManageProviderProps> = ({children}) => {
    const [accountInfo, setAccountInfo] = useState<Account|AccountStatus>(AccountStatus.NotLoaded);
    const [orgInfo, setOrgInfo] = useState<Organization|null>(null);
    const [userId, setUserId] = useState<string>('');
    const [userName, setUserName] = useState<string>('');

    
    async function getAccount(forceReload?: boolean): Promise<Account|AccountStatus> {
        return new Promise((resolve, reject) => {
            if (forceReload || accountInfo === AccountStatus.NotLoaded ) {
                getAccountInfo().then((AccountResponse: AccountResponse) => {
                    console.log("AccountResponse", AccountResponse)
                    if (AccountResponse.organization !== null) {
                        setOrgInfo(AccountResponse.organization);
                    }
                    console.log(AccountResponse);
                    let incomingAccountInfo = AccountResponse.account;
                    if (incomingAccountInfo === null) {
                        setAccountInfo(AccountStatus.NotLoggedIn)
                        resolve(AccountStatus.NotLoggedIn)
                    } else {
                        setAccountInfo(incomingAccountInfo);
                        resolve(incomingAccountInfo);
                    }
                }).catch((error) => {
                    setAccountInfo(AccountStatus.NotLoggedIn)
                    resolve(AccountStatus.NotLoggedIn)
                })
            }
            else if (accountInfo === AccountStatus.NotLoggedIn) {
                console.log("Not logged in", accountInfo)
                resolve(AccountStatus.NotLoggedIn)
            } else {    
                let accountInfoCopy = accountInfo as Account;
                console.log("else", accountInfoCopy)
                if (accountInfoCopy === null) {
                    resolve(AccountStatus.NotLoggedIn)
                } else {
                    resolve(accountInfo as Account)
                }
            }
        })
    }

    async function updateAccount(accountInfo: Partial<Account>): Promise<Account> {
        return new Promise((resolve, reject) => {
            updateAccountInfo(accountInfo).then((AccountResponse: AccountResponse) => {
                if (AccountResponse.organization !== null) {
                    setOrgInfo(AccountResponse.organization);
                }
                fetchAuthSession({forceRefresh:true}).then((_session) => {
                    let incomingAccountInfo = AccountResponse.account;
                    if (incomingAccountInfo === null) {
                        reject("Error updating account")
                    } else {
                        // need to refresh cognito jwt token
                        setAccountInfo(incomingAccountInfo);
                        resolve(incomingAccountInfo);
                    }
                }).catch((error) => {
                    reject(error);
                })
            }).catch((error) => {
                reject(error);
            })
        });
    }

    function resetAccount() {
        setAccountInfo(AccountStatus.NotLoggedIn)
        setUserId('')
        setUserName('')
    }

    return (
        <ManageContext.Provider value={{
            accountInfo,
            orgInfo, setOrgInfo,
            userId, setUserId,
            userName, setUserName,
            getAccount,
            updateAccount,
            resetAccount
        }}>
            {children}
        </ManageContext.Provider>
    );
}

const useManageContext = () => {
    const context = useContext(ManageContext);
    if (!context) {
      throw new Error('useMyContext must be used within a MyContextProvider');
    }
    return context;
};

export default ManageProvider;
export {AccountStatus, ManageContext, useManageContext};